<!-- Brand Logo -->
<a class="text-decoration-none text-center">
    <pf-image
            alt="Logo"
            class="ml-2"
            src="assets/img/eshed-logo.png" width="200"
    ></pf-image>
</a>

<!-- Sidebar -->
<div class="sidebar">
    <!-- Sidebar user (optional) -->
    <!--<div class="user-panel mt-3 pb-3 mb-3 d-flex">
      <div class="image">
        <pf-image
          [src]="user.picture"
          alt="User Image"
          class="img-circle"
          fallback-src="assets/img/default-profile.png"
          height="34"
          rounded
          width="34"
        ></pf-image>
      </div>
      <div class="info">
        <a [routerLink]="['/profile']" class="d-block">
          {{ user.email }}
        </a>
      </div>
    </div>-->

    <!--<div class="form-inline">
      <app-sidebar-search></app-sidebar-search>
    </div>-->

    <!-- Sidebar Menu -->
    <nav class="mt-2" style="overflow-y: hidden; overflow-x: hidden">
        <ul
                class="nav nav-pills nav-sidebar flex-column fw-bold"
                data-accordion="false"
                data-widget="treeview"
                role="menu"
        >
            <app-menu-item
                    *ngFor="let item of menu"
                    [menuItem]="item"
            ></app-menu-item>
        </ul>
    </nav>
</div>
