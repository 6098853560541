<app-header class=""></app-header>

<app-menu-sidebar class="bg-white position-fixed"></app-menu-sidebar>

<div class="content-wrapper">
  <router-outlet></router-outlet>
</div>

<app-footer class=""></app-footer>
<app-control-sidebar></app-control-sidebar>
<div (click)="onToggleMenuSidebar()" id="sidebar-overlay"></div>
