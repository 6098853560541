import {Component, OnInit, Renderer2} from "@angular/core";
import {ToastrService} from "ngx-toastr";
import {AppService} from "@services/app.service";
import {Router} from "@angular/router";
import {NgxSpinnerService} from "ngx-spinner";
import {ConfigService} from "@/config.service";
import {Subject, takeUntil, tap} from 'rxjs';
import {ProductOrders} from "@/model/orders/ProductOrders";
import {DateTime} from 'luxon';
import {OrderList} from "@/model/orders/OrderList";
import {LoggingService} from "@services/logging.service";
import {environment} from "../../../../environments/environment";

@Component({
    selector: 'app-product-orders',
    templateUrl: './product-orders.component.html',
    styleUrls: ['./product-orders.component.scss']
})

export class ProductOrdersComponent implements OnInit {
    destroy$: Subject<boolean> = new Subject<boolean>();
    productOrders: ProductOrders[] = [];
    tableProductOrders: ProductOrders[] = [];
    productOrderStatus: string[] = [];
    orderStatus: string[] = [];
    postStatus: string[] = [];
    postOrderStatus: string[] = [];
    area: string;
    city: string;
    state: string;
    pinCode: string;
    searchText;
    orderDetailModal: boolean = false;
    productOrderDetail: ProductOrders | undefined;
    orderID: string;
    orderDate: string;
    orderList: OrderList[] = [];
    netAmount: number;
    totalAmount: number;
    payableAmount: number;
    paymentStatus: string;
    addressType: string;
    doorNo: string;
    streetName: string;
    deliveryArea: string;
    village: string;
    deliveryCity: string;
    deliveryState: string;
    deliveryPinCode: string;
    landmark: string;
    tax: number;
    isLocalhost = window.location.hostname === 'localhost';


    constructor(
        private renderer: Renderer2,
        private toastr: ToastrService,
        private appService: AppService,
        private router: Router,
        private spinner: NgxSpinnerService,
        private configService: ConfigService,
        private loggingService: LoggingService
    ) {
    }

    ngOnInit() {
        this.getProductOrderList();
    }


    //Get all Order List
    getProductOrderList() {
        this.spinner.show();
        this.appService.getAllProductOrders()
            .pipe(
                takeUntil(this.destroy$),
                tap(data => {
                    const addresses = data.map(order => order.deliveryAddress);
                    //Duplicate Order status remove from productOrderStatus and  store unique status in this.productOrderStatus
                    this.productOrderStatus = Array.from(new Set(data.map(order => order.productOrderStatus)));
                }),
            )
            .subscribe(
                (productOrders) => {
                    this.spinner.hide();
                    this.productOrders = productOrders;
                    /*this.tableProductOrders = productOrders;*/
                    if (!environment.production && this.isLocalhost) {
                        console.log('product order list::', this.productOrders);
                    }
                },
                (error: any) => {
                    this.toastr.error(error.error?.msg || "Something went wrong, please try again");
                    console.log("error:", error.error?.msg || "Something went wrong, please try again");
                    this.spinner.hide();
                },
                () => {
                    this.spinner.hide();
                }
            );
    }


    /* selectOrderStatus(event: any) {
         this.tableProductOrders = [];
         const value = event.target.value;
         this.loggingService.log(value)
         for (let table of this.productOrders) {
             if (value == table.productOrderStatus) {
                 this.tableProductOrders.push(table);
                 this.loggingService.log(table.productOrderStatus)
             }
         }
         this.loggingService.log('OrderStatusBasedData::', this.tableProductOrders);
     }*/


    // Select Order status for filter
    selectOrderStatus(event: any) {
        const value = event.target.value;
        this.tableProductOrders = this.productOrders.sort((a, b) => b.productOrderId - a.productOrderId);
        this.tableProductOrders = this.productOrders.filter(product => product.productOrderStatus === value);
        if (!environment.production && this.isLocalhost) {
            console.log('Product Order Status Data::', this.tableProductOrders);
        }
    }


    // Order ID based Products Show in popup Modal
    openModal(productOrderId: number) {
        this.orderDetailModal = true;
        this.appService.getProductOrderByID(productOrderId)
            .pipe(takeUntil(this.destroy$),
                tap(ordersList => {
                    this.spinner.hide();
                    this.orderList = ordersList.listOrderDetails;
                    const address = ordersList.deliveryAddress;
                    this.addressType = address.addressType;
                    this.deliveryArea = address.area;
                    this.deliveryCity = address.city;
                    this.deliveryPinCode = address.pinCode;
                    this.deliveryState = address.state;
                    this.landmark = address.landmark;
                    this.doorNo = address.doorNo;
                    this.streetName = address.streetName;
                })
            )
            .subscribe((productOrder) => {
                    this.spinner.hide();
                    if (!environment.production && this.isLocalhost) {
                        console.log('Product Order Detail::', productOrder);
                    }
                    this.productOrderDetail = productOrder;
                    this.orderID = this.productOrderDetail.productOrderUID;
                    const epochDate = this.productOrderDetail.productOrderDate;
                    const dt = DateTime.fromMillis(epochDate);
                    this.orderDate = dt.toFormat('cccc, MMMM dd, yyyy h:mm a');
                    this.totalAmount = this.productOrderDetail.totalAmount;
                    this.payableAmount = this.productOrderDetail.payableAmount;
                    this.netAmount = this.productOrderDetail.netAmount;
                    this.paymentStatus = this.productOrderDetail.paymentStatus;
                    this.tax = this.payableAmount - this.totalAmount
                },
                (error: any) => {
                    this.toastr.error(error.error?.message || "Something went wrong, please try again");
                    console.log("error:", error.error?.message || "Something went wrong, please try again");
                    this.spinner.hide();
                },
                () => {
                    this.spinner.hide();
                }
            );
    }

}