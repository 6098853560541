import {Component, OnInit, Renderer2} from "@angular/core";
import {ToastrService} from "ngx-toastr";
import {AppService} from "@services/app.service";
import {Router} from "@angular/router";
import {NgxSpinnerService} from "ngx-spinner";
import {Subject, takeUntil} from 'rxjs';
import {ProductDetails} from "@/model/product/ProductDetails";
import {ImageDetails} from "@/model/asset-details/ImageDetails";
import {FeatureDetails} from "@/model/feature/FeatureDetails";
import {ConfigService} from "@/config.service";
import {LoggingService} from "@services/logging.service";
import {environment} from "../../../environments/environment";

@Component({
    selector: 'app-product-detail',
    templateUrl: './product-detail.component.html',
    styleUrls: ['./product-detail..component.scss']
})

export class ProductDetailComponent implements OnInit {
    destroy$: Subject<boolean> = new Subject<boolean>();
    productId: number;
    productDetailsById: ProductDetails | undefined;
    productName: string;
    phoneNo: string;
    emailId: string;
    location: string;
    quantityNo: number;
    quantityType: string;
    quantityValue: number;
    description: string;
    unique: string;
    featureId: number;
    featureName: string;
    featureValue: string;
    friendlyValue: string;
    friendlyName: string;
    paymentStatus: string;
    isTrader: string;
    isVerified: string;
    price: number;
    tax: number;
    discount: number;
    netPrice: number;
    productImageList: ImageDetails[] = [];
    featureList: FeatureDetails[] = [];
    endPoint: string;
    isLocalhost = window.location.hostname === 'localhost';
    selectedImageUrl: string;
    imageModalShow = false;

    constructor(
        private renderer: Renderer2,
        private toastr: ToastrService,
        private appService: AppService,
        private router: Router,
        private spinner: NgxSpinnerService,
        private configService: ConfigService,
        private loggingService: LoggingService
    ) {
    }

    ngOnInit() {
        this.getProductDetails();
        window.scrollTo(0, 0);
    }

    openModal(image: any) {
        this.selectedImageUrl = `${this.endPoint}/api/image-details/get/${image.imageId}`;
        this.imageModalShow = true;
    }


    // When return any Page Remove ProductID from Local Storage
    ngOnDestroy() {
        localStorage.removeItem('productId');
    }

    getImageUrl(imageId: string): string {
        return `${this.endPoint}/api/image-details/get/${imageId}`;
    }


    // Click a view button Product ID based Product Get Functionality
    getProductDetails() {
        this.spinner.show();
        this.productId = Number(localStorage.getItem('productId'));
        this.appService
            .getProductDetailsById(this.productId)
            .pipe(takeUntil(this.destroy$))
            .subscribe((data) => {
                    this.spinner.hide();
                    this.productDetailsById = data;
                    if (!environment.production && this.isLocalhost) {
                        console.log("product detail::", this.productDetailsById);
                    }
                    this.endPoint = this.configService.getConfig().END_POINT;
                    const product = this.productDetailsById;
                    this.productImageList = product.productImageList;
                    this.productId = product.productId;
                    this.productName = product.productName;
                    this.phoneNo = product.phoneNumber;
                    this.location = product.location;
                    this.quantityNo = product.quantityNo;
                    this.quantityType = product.quantityType;
                    this.quantityValue = product.quantityValue;
                    this.description = product.description;
                    this.unique = product.uniqueness;
                    this.isVerified = product.approvalStatus;
                    this.featureList = product.productFeatureList;
                    this.price = product.grossPrice;
                    this.tax = product.tax;
                    this.discount = product.discount;
                    this.netPrice = product.netPrice;
                },
                (error: any) => {
                    console.error("Error:", error);
                    this.spinner.hide();

                    const errorMessage = error?.error?.message || "Something went wrong, please try again";
                    this.toastr.error(errorMessage);
                })
    }


    /*Product ID based Product status Approval*/
    approveStatus(approvalStatus: string, productDetailsId: number) {
        const productStatus = {
            productId: productDetailsId,
            approvalStatus: approvalStatus
        }
        this.spinner.show();
        this.appService
            .updateProductStatus(productStatus)
            .pipe(takeUntil(this.destroy$))
            .subscribe((data) => {
                    this.spinner.hide();
                    if (!environment.production && this.isLocalhost) {
                        console.log("update product status::", data)
                    }
                    this.toastr.success('Product status updated');
                    this.productDetailsById.approvalStatus = data.approvalStatus;
                    this.getProductDetails();
                },
                (error: any) => {
                    console.error("Error:", error);
                    this.spinner.hide();

                    const errorMessage = error?.error?.message || "Something went wrong, please try again";
                    this.toastr.error(errorMessage);
                });
    }
}