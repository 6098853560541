import {Component} from "@angular/core";

@Component({
    selector: 'app-ui-kit',
    templateUrl: './ui-kit.component.html',
    styleUrls: ['./ui-kit.component.scss']
})
export class UiKitComponent {

    activeTab: string = 'tab_1';
    modal: boolean = false;

    changeTab(tabId: string) {
        this.activeTab = tabId;
    }

    modalShow() {
        this.modal = true;
    }

    closeModal() {
        this.modal = false;
    }
}