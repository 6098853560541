export const countryOptions = [
    {
        "name": "India",
        "flag": "🇮🇳",
        "code": "IN",
        "value": "91"
    },
    {"name": "United Kingdom",
        "flag": "🇬🇧",
        "code": "GB",
        "value": "44"
    },
    {
        "name": "United States",
        "flag": "🇺🇸",
        "code": "US",
        "value": "1"
    },
    {
    "name": "Afghanistan",
    "flag": "🇦🇫",
    "code": "AF",
    "value": "93"
},
    {"name": "Åland Islands", "flag": "🇦🇽", "code": "AX", "value": "358"}, {
    "name": "Albania",
    "flag": "🇦🇱",
    "code": "AL",
    "value": "355"
}, {"name": "Algeria", "flag": "🇩🇿", "code": "DZ", "value": "213"}, {
    "name": "American Samoa",
    "flag": "🇦🇸",
    "code": "AS",
    "value": "1684"
}, {"name": "Andorra", "flag": "🇦🇩", "code": "AD", "value": "376"}, {
    "name": "Angola",
    "flag": "🇦🇴",
    "code": "AO",
    "value": "244"
}, {"name": "Anguilla", "flag": "🇦🇮", "code": "AI", "value": "1264"}, {
    "name": "Antarctica",
    "flag": "🇦🇶",
    "code": "AQ",
    "value": "672"
}, {"name": "Antigua and Barbuda", "flag": "🇦🇬", "code": "AG", "value": "1268"}, {
    "name": "Argentina",
    "flag": "🇦🇷",
    "code": "AR",
    "value": "54"
}, {"name": "Armenia", "flag": "🇦🇲", "code": "AM", "value": "374"}, {
    "name": "Aruba",
    "flag": "🇦🇼",
    "code": "AW",
    "value": "297"
}, {"name": "Australia", "flag": "🇦🇺", "code": "AU", "value": "61"}, {
    "name": "Austria",
    "flag": "🇦🇹",
    "code": "AT",
    "value": "43"
}, {"name": "Azerbaijan", "flag": "🇦🇿", "code": "AZ", "value": "994"}, {
    "name": "Bahamas",
    "flag": "🇧🇸",
    "code": "BS",
    "value": "1242"
}, {"name": "Bahrain", "flag": "🇧🇭", "code": "BH", "value": "973"}, {
    "name": "Bangladesh",
    "flag": "🇧🇩",
    "code": "BD",
    "value": "880"
}, {"name": "Barbados", "flag": "🇧🇧", "code": "BB", "value": "1246"}, {
    "name": "Belarus",
    "flag": "🇧🇾",
    "code": "BY",
    "value": "375"
}, {"name": "Belgium", "flag": "🇧🇪", "code": "BE", "value": "32"}, {
    "name": "Belize",
    "flag": "🇧🇿",
    "code": "BZ",
    "value": "501"
}, {"name": "Benin", "flag": "🇧🇯", "code": "BJ", "value": "229"}, {
    "name": "Bermuda",
    "flag": "🇧🇲",
    "code": "BM",
    "value": "1441"
}, {
    "name": "Bhutan",
    "flag": "🇧🇹",
    "code": "BT",
    "value": "975"
}, {
    "name": "Bolivia, Plurinational State of bolivia",
    "flag": "🇧🇴",
    "code": "BO",
    "value": "591"
}, {"name": "Bosnia and Herzegovina", "flag": "🇧🇦", "code": "BA", "value": "387"}, {
    "name": "Botswana",
    "flag": "🇧🇼",
    "code": "BW",
    "value": "267"
}, {"name": "Bouvet Island", "flag": "🇧🇻", "code": "BV", "value": "47"}, {
    "name": "Brazil",
    "flag": "🇧🇷",
    "code": "BR",
    "value": "55"
}, {
    "name": "British Indian Ocean Territory",
    "flag": "🇮🇴",
    "code": "IO",
    "value": "246"
}, {"name": "Brunei Darussalam", "flag": "🇧🇳", "code": "BN", "value": "673"}, {
    "name": "Bulgaria",
    "flag": "🇧🇬",
    "code": "BG",
    "value": "359"
}, {"name": "Burkina Faso", "flag": "🇧🇫", "code": "BF", "value": "226"}, {
    "name": "Burundi",
    "flag": "🇧🇮",
    "code": "BI",
    "value": "257"
}, {"name": "Cambodia", "flag": "🇰🇭", "code": "KH", "value": "855"}, {
    "name": "Cameroon",
    "flag": "🇨🇲",
    "code": "CM",
    "value": "237"
}, {"name": "Canada", "flag": "🇨🇦", "code": "CA", "value": "1"}, {
    "name": "Cape Verde",
    "flag": "🇨🇻",
    "code": "CV",
    "value": "238"
}, {"name": "Cayman Islands", "flag": "🇰🇾", "code": "KY", "value": "345"}, {
    "name": "Central African Republic",
    "flag": "🇨🇫",
    "code": "CF",
    "value": "236"
}, {"name": "Chad", "flag": "🇹🇩", "code": "TD", "value": "235"}, {
    "name": "Chile",
    "flag": "🇨🇱",
    "code": "CL",
    "value": "56"
}, {"name": "China", "flag": "🇨🇳", "code": "CN", "value": "86"}, {
    "name": "Christmas Island",
    "flag": "🇨🇽",
    "code": "CX",
    "value": "61"
}, {"name": "Cocos (Keeling) Islands", "flag": "🇨🇨", "code": "CC", "value": "61"}, {
    "name": "Colombia",
    "flag": "🇨🇴",
    "code": "CO",
    "value": "57"
}, {"name": "Comoros", "flag": "🇰🇲", "code": "KM", "value": "269"}, {
    "name": "Congo",
    "flag": "🇨🇬",
    "code": "CG",
    "value": "242"
}, {
    "name": "Congo, The Democratic Republic of the Congo",
    "flag": "🇨🇩",
    "code": "CD",
    "value": "243"
}, {"name": "Cook Islands", "flag": "🇨🇰", "code": "CK", "value": "682"}, {
    "name": "Costa Rica",
    "flag": "🇨🇷",
    "code": "CR",
    "value": "506"
}, {"name": "Cote d'Ivoire", "flag": "🇨🇮", "code": "CI", "value": "225"}, {
    "name": "Croatia",
    "flag": "🇭🇷",
    "code": "HR",
    "value": "385"
}, {"name": "Cuba", "flag": "🇨🇺", "code": "CU", "value": "53"}, {
    "name": "Cyprus",
    "flag": "🇨🇾",
    "code": "CY",
    "value": "357"
}, {"name": "Czech Republic", "flag": "🇨🇿", "code": "CZ", "value": "420"}, {
    "name": "Denmark",
    "flag": "🇩🇰",
    "code": "DK",
    "value": "45"
}, {"name": "Djibouti", "flag": "🇩🇯", "code": "DJ", "value": "253"}, {
    "name": "Dominica",
    "flag": "🇩🇲",
    "code": "DM",
    "value": "1767"
}, {"name": "Dominican Republic", "flag": "🇩🇴", "code": "DO", "value": "1849"}, {
    "name": "Ecuador",
    "flag": "🇪🇨",
    "code": "EC",
    "value": "593"
}, {"name": "Egypt", "flag": "🇪🇬", "code": "EG", "value": "20"}, {
    "name": "El Salvador",
    "flag": "🇸🇻",
    "code": "SV",
    "value": "503"
}, {"name": "Equatorial Guinea", "flag": "🇬🇶", "code": "GQ", "value": "240"}, {
    "name": "Eritrea",
    "flag": "🇪🇷",
    "code": "ER",
    "value": "291"
}, {"name": "Estonia", "flag": "🇪🇪", "code": "EE", "value": "372"}, {
    "name": "Ethiopia",
    "flag": "🇪🇹",
    "code": "ET",
    "value": "251"
}, {"name": "Falkland Islands (Malvinas)", "flag": "🇫🇰", "code": "FK", "value": "500"}, {
    "name": "Faroe Islands",
    "flag": "🇫🇴",
    "code": "FO",
    "value": "298"
}, {"name": "Fiji", "flag": "🇫🇯", "code": "FJ", "value": "679"}, {
    "name": "Finland",
    "flag": "🇫🇮",
    "code": "FI",
    "value": "358"
}, {"name": "France", "flag": "🇫🇷", "code": "FR", "value": "33"}, {
    "name": "French Guiana",
    "flag": "🇬🇫",
    "code": "GF",
    "value": "594"
}, {
    "name": "French Polynesia",
    "flag": "🇵🇫",
    "code": "PF",
    "value": "689"
}, {"name": "French Southern Territories", "flag": "🇹🇫", "code": "TF", "value": "262"}, {
    "name": "Gabon",
    "flag": "🇬🇦",
    "code": "GA",
    "value": "241"
}, {"name": "Gambia", "flag": "🇬🇲", "code": "GM", "value": "220"}, {
    "name": "Georgia",
    "flag": "🇬🇪",
    "code": "GE",
    "value": "995"
}, {"name": "Germany", "flag": "🇩🇪", "code": "DE", "value": "49"}, {
    "name": "Ghana",
    "flag": "🇬🇭",
    "code": "GH",
    "value": "233"
}, {"name": "Gibraltar", "flag": "🇬🇮", "code": "GI", "value": "350"}, {
    "name": "Greece",
    "flag": "🇬🇷",
    "code": "GR",
    "value": "30"
}, {"name": "Greenland", "flag": "🇬🇱", "code": "GL", "value": "299"}, {
    "name": "Grenada",
    "flag": "🇬🇩",
    "code": "GD",
    "value": "1473"
}, {"name": "Guadeloupe", "flag": "🇬🇵", "code": "GP", "value": "590"}, {
    "name": "Guam",
    "flag": "🇬🇺",
    "code": "GU",
    "value": "1671"
}, {"name": "Guatemala", "flag": "🇬🇹", "code": "GT", "value": "502"}, {
    "name": "Guernsey",
    "flag": "🇬🇬",
    "code": "GG",
    "value": "44"
}, {"name": "Guinea", "flag": "🇬🇳", "code": "GN", "value": "224"}, {
    "name": "Guinea-Bissau",
    "flag": "🇬🇼",
    "code": "GW",
    "value": "245"
}, {"name": "Guyana", "flag": "🇬🇾", "code": "GY", "value": "592"}, {
    "name": "Haiti",
    "flag": "🇭🇹",
    "code": "HT",
    "value": "509"
}, {
    "name": "Heard Island and Mcdonald Islands",
    "flag": "🇭🇲",
    "code": "HM",
    "value": "672"
}, {"name": "Holy See (Vatican City State)", "flag": "🇻🇦", "code": "VA", "value": "379"}, {
    "name": "Honduras",
    "flag": "🇭🇳",
    "code": "HN",
    "value": "504"
}, {"name": "Hong Kong", "flag": "🇭🇰", "code": "HK", "value": "852"}, {
    "name": "Hungary",
    "flag": "🇭🇺",
    "code": "HU",
    "value": "36"
}, {"name": "Iceland", "flag": "🇮🇸", "code": "IS", "value": "354"}, {
    "name": "Indonesia",
    "flag": "🇮🇩",
    "code": "ID",
    "value": "62"
}, {
    "name": "Iran, Islamic Republic of Persian Gulf",
    "flag": "🇮🇷",
    "code": "IR",
    "value": "98"
}, {"name": "Iraq", "flag": "🇮🇶", "code": "IQ", "value": "964"}, {
    "name": "Ireland",
    "flag": "🇮🇪",
    "code": "IE",
    "value": "353"
}, {"name": "Isle of Man", "flag": "🇮🇲", "code": "IM", "value": "44"}, {
    "name": "Israel",
    "flag": "🇮🇱",
    "code": "IL",
    "value": "972"
}, {"name": "Italy", "flag": "🇮🇹", "code": "IT", "value": "39"}, {
    "name": "Jamaica",
    "flag": "🇯🇲",
    "code": "JM",
    "value": "1876"
}, {"name": "Japan", "flag": "🇯🇵", "code": "JP", "value": "81"}, {
    "name": "Jersey",
    "flag": "🇯🇪",
    "code": "JE",
    "value": "44"
}, {"name": "Jordan", "flag": "🇯🇴", "code": "JO", "value": "962"}, {
    "name": "Kazakhstan",
    "flag": "🇰🇿",
    "code": "KZ",
    "value": "7"
}, {"name": "Kenya", "flag": "🇰🇪", "code": "KE", "value": "254"}, {
    "name": "Kiribati",
    "flag": "🇰🇮",
    "code": "KI",
    "value": "686"
}, {
    "name": "Korea, Democratic People's Republic of Korea",
    "flag": "🇰🇵",
    "code": "KP",
    "value": "850"
}, {"name": "Korea, Republic of South Korea", "flag": "🇰🇷", "code": "KR", "value": "82"}, {
    "name": "Kosovo",
    "flag": "🇽🇰",
    "code": "XK",
    "value": "383"
}, {"name": "Kuwait", "flag": "🇰🇼", "code": "KW", "value": "965"}, {
    "name": "Kyrgyzstan",
    "flag": "🇰🇬",
    "code": "KG",
    "value": "996"
}, {"name": "Laos", "flag": "🇱🇦", "code": "LA", "value": "856"}, {
    "name": "Latvia",
    "flag": "🇱🇻",
    "code": "LV",
    "value": "371"
}, {"name": "Lebanon", "flag": "🇱🇧", "code": "LB", "value": "961"}, {
    "name": "Lesotho",
    "flag": "🇱🇸",
    "code": "LS",
    "value": "266"
}, {"name": "Liberia", "flag": "🇱🇷", "code": "LR", "value": "231"}, {
    "name": "Libyan Arab Jamahiriya",
    "flag": "🇱🇾",
    "code": "LY",
    "value": "218"
}, {"name": "Liechtenstein", "flag": "🇱🇮", "code": "LI", "value": "423"}, {
    "name": "Lithuania",
    "flag": "🇱🇹",
    "code": "LT",
    "value": "370"
}, {"name": "Luxembourg", "flag": "🇱🇺", "code": "LU", "value": "352"}, {
    "name": "Macao",
    "flag": "🇲🇴",
    "code": "MO",
    "value": "853"
}, {"name": "Macedonia", "flag": "🇲🇰", "code": "MK", "value": "389"}, {
    "name": "Madagascar",
    "flag": "🇲🇬",
    "code": "MG",
    "value": "261"
}, {"name": "Malawi", "flag": "🇲🇼", "code": "MW", "value": "265"}, {
    "name": "Malaysia",
    "flag": "🇲🇾",
    "code": "MY",
    "value": "60"
}, {"name": "Maldives", "flag": "🇲🇻", "code": "MV", "value": "960"}, {
    "name": "Mali",
    "flag": "🇲🇱",
    "code": "ML",
    "value": "223"
}, {"name": "Malta", "flag": "🇲🇹", "code": "MT", "value": "356"}, {
    "name": "Marshall Islands",
    "flag": "🇲🇭",
    "code": "MH",
    "value": "692"
}, {"name": "Martinique", "flag": "🇲🇶", "code": "MQ", "value": "596"}, {
    "name": "Mauritania",
    "flag": "🇲🇷",
    "code": "MR",
    "value": "222"
}, {"name": "Mauritius", "flag": "🇲🇺", "code": "MU", "value": "230"}, {
    "name": "Mayotte",
    "flag": "🇾🇹",
    "code": "YT",
    "value": "262"
}, {
    "name": "Mexico",
    "flag": "🇲🇽",
    "code": "MX",
    "value": "52"
}, {
    "name": "Micronesia, Federated States of Micronesia",
    "flag": "🇫🇲",
    "code": "FM",
    "value": "691"
}, {"name": "Moldova", "flag": "🇲🇩", "code": "MD", "value": "373"}, {
    "name": "Monaco",
    "flag": "🇲🇨",
    "code": "MC",
    "value": "377"
}, {"name": "Mongolia", "flag": "🇲🇳", "code": "MN", "value": "976"}, {
    "name": "Montenegro",
    "flag": "🇲🇪",
    "code": "ME",
    "value": "382"
}, {"name": "Montserrat", "flag": "🇲🇸", "code": "MS", "value": "1664"}, {
    "name": "Morocco",
    "flag": "🇲🇦",
    "code": "MA",
    "value": "212"
}, {"name": "Mozambique", "flag": "🇲🇿", "code": "MZ", "value": "258"}, {
    "name": "Myanmar",
    "flag": "🇲🇲",
    "code": "MM",
    "value": "95"
}, {"name": "Namibia", "flag": "🇳🇦", "code": "NA", "value": "264"}, {
    "name": "Nauru",
    "flag": "🇳🇷",
    "code": "NR",
    "value": "674"
}, {"name": "Nepal", "flag": "🇳🇵", "code": "NP", "value": "977"}, {
    "name": "Netherlands",
    "flag": "🇳🇱",
    "code": "NL",
    "value": "31"
}, {"name": "Netherlands Antilles", "flag": "", "code": "AN", "value": "599"}, {
    "name": "New Caledonia",
    "flag": "🇳🇨",
    "code": "NC",
    "value": "687"
}, {"name": "New Zealand", "flag": "🇳🇿", "code": "NZ", "value": "64"}, {
    "name": "Nicaragua",
    "flag": "🇳🇮",
    "code": "NI",
    "value": "505"
}, {"name": "Niger", "flag": "🇳🇪", "code": "NE", "value": "227"}, {
    "name": "Nigeria",
    "flag": "🇳🇬",
    "code": "NG",
    "value": "234"
}, {"name": "Niue", "flag": "🇳🇺", "code": "NU", "value": "683"}, {
    "name": "Norfolk Island",
    "flag": "🇳🇫",
    "code": "NF",
    "value": "672"
}, {"name": "Northern Mariana Islands", "flag": "🇲🇵", "code": "MP", "value": "1670"}, {
    "name": "Norway",
    "flag": "🇳🇴",
    "code": "NO",
    "value": "47"
}, {"name": "Oman", "flag": "🇴🇲", "code": "OM", "value": "968"}, {
    "name": "Pakistan",
    "flag": "🇵🇰",
    "code": "PK",
    "value": "92"
}, {"name": "Palau", "flag": "🇵🇼", "code": "PW", "value": "680"}, {
    "name": "Palestinian Territory, Occupied",
    "flag": "🇵🇸",
    "code": "PS",
    "value": "970"
}, {"name": "Panama", "flag": "🇵🇦", "code": "PA", "value": "507"}, {
    "name": "Papua New Guinea",
    "flag": "🇵🇬",
    "code": "PG",
    "value": "675"
}, {"name": "Paraguay", "flag": "🇵🇾", "code": "PY", "value": "595"}, {
    "name": "Peru",
    "flag": "🇵🇪",
    "code": "PE",
    "value": "51"
}, {"name": "Philippines", "flag": "🇵🇭", "code": "PH", "value": "63"}, {
    "name": "Pitcairn",
    "flag": "🇵🇳",
    "code": "PN",
    "value": "64"
}, {"name": "Poland", "flag": "🇵🇱", "code": "PL", "value": "48"}, {
    "name": "Portugal",
    "flag": "🇵🇹",
    "code": "PT",
    "value": "351"
}, {"name": "Puerto Rico", "flag": "🇵🇷", "code": "PR", "value": "1939"}, {
    "name": "Qatar",
    "flag": "🇶🇦",
    "code": "QA",
    "value": "974"
}, {"name": "Romania", "flag": "🇷🇴", "code": "RO", "value": "40"}, {
    "name": "Russia",
    "flag": "🇷🇺",
    "code": "RU",
    "value": "7"
}, {"name": "Rwanda", "flag": "🇷🇼", "code": "RW", "value": "250"}, {
    "name": "Reunion",
    "flag": "🇷🇪",
    "code": "RE",
    "value": "262"
}, {
    "name": "Saint Barthelemy",
    "flag": "🇧🇱",
    "code": "BL",
    "value": "590"
}, {
    "name": "Saint Helena, Ascension and Tristan Da Cunha",
    "flag": "🇸🇭",
    "code": "SH",
    "value": "290"
}, {"name": "Saint Kitts and Nevis", "flag": "🇰🇳", "code": "KN", "value": "1869"}, {
    "name": "Saint Lucia",
    "flag": "🇱🇨",
    "code": "LC",
    "value": "1758"
}, {"name": "Saint Martin", "flag": "🇲🇫", "code": "MF", "value": "590"}, {
    "name": "Saint Pierre and Miquelon",
    "flag": "🇵🇲",
    "code": "PM",
    "value": "508"
}, {"name": "Saint Vincent and the Grenadines", "flag": "🇻🇨", "code": "VC", "value": "1784"}, {
    "name": "Samoa",
    "flag": "🇼🇸",
    "code": "WS",
    "value": "685"
}, {"name": "San Marino", "flag": "🇸🇲", "code": "SM", "value": "378"}, {
    "name": "Sao Tome and Principe",
    "flag": "🇸🇹",
    "code": "ST",
    "value": "239"
}, {"name": "Saudi Arabia", "flag": "🇸🇦", "code": "SA", "value": "966"}, {
    "name": "Senegal",
    "flag": "🇸🇳",
    "code": "SN",
    "value": "221"
}, {"name": "Serbia", "flag": "🇷🇸", "code": "RS", "value": "381"}, {
    "name": "Seychelles",
    "flag": "🇸🇨",
    "code": "SC",
    "value": "248"
}, {"name": "Sierra Leone", "flag": "🇸🇱", "code": "SL", "value": "232"}, {
    "name": "Singapore",
    "flag": "🇸🇬",
    "code": "SG",
    "value": "65"
}, {"name": "Slovakia", "flag": "🇸🇰", "code": "SK", "value": "421"}, {
    "name": "Slovenia",
    "flag": "🇸🇮",
    "code": "SI",
    "value": "386"
}, {"name": "Solomon Islands", "flag": "🇸🇧", "code": "SB", "value": "677"}, {
    "name": "Somalia",
    "flag": "🇸🇴",
    "code": "SO",
    "value": "252"
}, {"name": "South Africa", "flag": "🇿🇦", "code": "ZA", "value": "27"}, {
    "name": "South Sudan",
    "flag": "🇸🇸",
    "code": "SS",
    "value": "211"
}, {
    "name": "South Georgia and the South Sandwich Islands",
    "flag": "🇬🇸",
    "code": "GS",
    "value": "500"
}, {"name": "Spain", "flag": "🇪🇸", "code": "ES", "value": "34"}, {
    "name": "Sri Lanka",
    "flag": "🇱🇰",
    "code": "LK",
    "value": "94"
}, {"name": "Sudan", "flag": "🇸🇩", "code": "SD", "value": "249"}, {
    "name": "Suriname",
    "flag": "🇸🇷",
    "code": "SR",
    "value": "597"
}, {"name": "Svalbard and Jan Mayen", "flag": "🇸🇯", "code": "SJ", "value": "47"}, {
    "name": "Eswatini",
    "flag": "🇸🇿",
    "code": "SZ",
    "value": "268"
}, {"name": "Sweden", "flag": "🇸🇪", "code": "SE", "value": "46"}, {
    "name": "Switzerland",
    "flag": "🇨🇭",
    "code": "CH",
    "value": "41"
}, {"name": "Syrian Arab Republic", "flag": "🇸🇾", "code": "SY", "value": "963"}, {
    "name": "Taiwan",
    "flag": "🇹🇼",
    "code": "TW",
    "value": "886"
}, {
    "name": "Tajikistan",
    "flag": "🇹🇯",
    "code": "TJ",
    "value": "992"
}, {
    "name": "Tanzania, United Republic of Tanzania",
    "flag": "🇹🇿",
    "code": "TZ",
    "value": "255"
}, {"name": "Thailand", "flag": "🇹🇭", "code": "TH", "value": "66"}, {
    "name": "Timor-Leste",
    "flag": "🇹🇱",
    "code": "TL",
    "value": "670"
}, {"name": "Togo", "flag": "🇹🇬", "code": "TG", "value": "228"}, {
    "name": "Tokelau",
    "flag": "🇹🇰",
    "code": "TK",
    "value": "690"
}, {"name": "Tonga", "flag": "🇹🇴", "code": "TO", "value": "676"}, {
    "name": "Trinidad and Tobago",
    "flag": "🇹🇹",
    "code": "TT",
    "value": "1868"
}, {"name": "Tunisia", "flag": "🇹🇳", "code": "TN", "value": "216"}, {
    "name": "Turkey",
    "flag": "🇹🇷",
    "code": "TR",
    "value": "90"
}, {"name": "Turkmenistan", "flag": "🇹🇲", "code": "TM", "value": "993"}, {
    "name": "Turks and Caicos Islands",
    "flag": "🇹🇨",
    "code": "TC",
    "value": "1649"
}, {"name": "Tuvalu", "flag": "🇹🇻", "code": "TV", "value": "688"}, {
    "name": "Uganda",
    "flag": "🇺🇬",
    "code": "UG",
    "value": "256"
}, {"name": "Ukraine", "flag": "🇺🇦", "code": "UA", "value": "380"}, {
    "name": "United Arab Emirates",
    "flag": "🇦🇪",
    "code": "AE",
    "value": "971"
}, {"name": "Uruguay", "flag": "🇺🇾", "code": "UY", "value": "598"}, {
    "name": "Uzbekistan",
    "flag": "🇺🇿",
    "code": "UZ",
    "value": "998"
}, {
    "name": "Vanuatu",
    "flag": "🇻🇺",
    "code": "VU",
    "value": "678"
}, {
    "name": "Venezuela, Bolivarian Republic of Venezuela",
    "flag": "🇻🇪",
    "code": "VE",
    "value": "58"
}, {"name": "Vietnam", "flag": "🇻🇳", "code": "VN", "value": "84"}, {
    "name": "Virgin Islands, British",
    "flag": "🇻🇬",
    "code": "VG",
    "value": "1284"
}, {"name": "Virgin Islands, U.S.", "flag": "🇻🇮", "code": "VI", "value": "1340"}, {
    "name": "Wallis and Futuna",
    "flag": "🇼🇫",
    "code": "WF",
    "value": "681"
}, {"name": "Yemen", "flag": "🇾🇪", "code": "YE", "value": "967"}, {
    "name": "Zambia",
    "flag": "🇿🇲",
    "code": "ZM",
    "value": "260"
}, {"name": "Zimbabwe", "flag": "🇿🇼", "code": "ZW", "value": "263"}]
