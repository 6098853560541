<section class="content">
    <div class="container-fluid col-lg-12 ml-1">
        <div class="row">
            <div class="row mt-3">
                <mat-card class="order-card">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-4 col-md-6 col-12">
                                <div class="form-floating">
                                    <select class="form-select" id="floatingSelect"
                                            aria-label="Floating label select example"
                                            (change)="selectOrderStatus($event)">
                                        <option selected disabled>Select Order Status</option>
                                        <option *ngFor="let status of this.postOrderStatus;">{{status}}</option>
                                    </select>
                                    <label for="floatingSelect">Order Status</label>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-12"></div>
                            <div class="col-lg-4 col-md-6 col-12">
                                <div class="form-floating">
                                    <input type="search" class="form-control" placeholder="search"
                                           [(ngModel)]="searchText">
                                    <label class="text-gray">Search</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <mat-card-content>
                        <div class="container-fluid mt-3" type="button">
                            <div class="table-responsive table-style table-bordered">
                                <table class="table table-green table-head-fixed">
                                    <thead class="h6">
                                    <tr>
                                        <th>S.No</th>
                                        <th>Order ID</th>
                                        <th>Delivery Address</th>
                                        <th>Order Status</th>
                                        <th>Payment Status</th>
                                    </tr>
                                    </thead>
                                    <tbody class="text-center">
                                    <tr *ngFor="let post of this.tablePostOrders | filter: searchText; let i = index;"
                                        (click)="openPostOrderModal(post.postOrderId)">
                                        <td>{{ i + 1 }}</td>
                                        <td>{{ post.postOrderId }}</td>
                                        <td *ngIf="post.deliveryAddress != null">{{post.deliveryAddress.doorNo}}
                                            , {{post.deliveryAddress.streetName}}, {{post.deliveryAddress.area}}
                                            , {{post.deliveryAddress.city}}, {{post.deliveryAddress.state}}
                                            , {{post.deliveryAddress.pinCode}}</td>
                                        <td *ngIf="post.deliveryAddress == null"> -</td>
                                        <td><span class="badge"
                                                  [ngClass]="{'badge-gradient-info': post.postOrderStatus === 'ORDERED'}">{{ post.postOrderStatus }}</span>
                                        </td>
                                        <td><span class="badge"
                                                  [ngClass]="{'badge-gradient-green': post.paymentStatus === 'PAID','badge-gradient-red': post.paymentStatus !== 'PAID'}">{{ post.paymentStatus }}</span>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
    </div>
</section>

<div class="modal" tabindex="-1" [style.display]="postOrderDetailModal ? 'block' : 'none'"
     style="background-color: rgba(87,86,86,0.64)">
    <div class="modal-dialog modal-xl modal-dialog-scrollable">
        <div class="modal-content">
            <div class="modal-header modal-header-bg m-3 p-5">
                <h2 class="modal-title">Order #{{ orderID }}</h2>
                <span class="mt-2 fw-lighter">{{ orderDate }}</span>
            </div>
            <div class="modal-body">
                <div class="container-fluid mt-3">
                    <div class="table-responsive table-style table-bordered">
                        <table class="table table-green table-head-fixed">
                            <thead class="h6">
                            <tr>
                                <th>S.No</th>
                                <th>Product Name</th>
                                <th>Quantity</th>
                                <th>Rate</th>
                                <th>Amount</th>
                            </tr>
                            </thead>
                            <tbody class="text-center">
                            <tr *ngFor="let orders of this.orderList; let i = index;">
                                <td>{{ i + 1 }}</td>
                                <td>{{ orders.itemName}}</td>
                                <td>{{ orders.itemCount }}</td>
                                <td>{{ orders.itemSRP| currency:'INR':'' }}</td>
                                <td>{{orders.itemCount * orders.itemSRP | currency:'INR':''}}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="row mt-3">
                        <div class="col-lg-7">
                            <div class="row mt-3 p-3">
                                <mat-card class="order-card bg-light p-3">
                                    <mat-card-content>
                                        <div class="row">
                                            <div class="col-12">
                                                <span class="text-gray fw-bold">Customer Address Details</span>
                                            </div>
                                        </div>
                                        <div class="row mt-3">
                                            <div class="col-12">
                                                {{doorNo}}, {{streetName}},{{deliveryArea}},{{deliveryCity}}
                                                , {{deliveryState}}
                                                ,{{deliveryPinCode}}
                                            </div>
                                        </div>
                                    </mat-card-content>
                                </mat-card>
                            </div>
                        </div>
                        <div class="col-lg-1"></div>
                        <div class="col-lg-4">
                            <div class="row mt-3">
                                <div class="col-lg-7">Total</div>
                                <div class="col-lg-5">{{totalAmount | currency:'INR':''}}</div>
                            </div>
                            <hr>
                            <div class="row mt-3">
                                <div class="col-lg-7">Order Total</div>
                                <div class="col-lg-5">{{payableAmount | currency:'INR':''}}</div>
                            </div>
                            <hr>
                            <div class="row mt-3">
                                <div class="col-lg-7">Payment Status</div>
                                <div class="col-lg-5">
                                    <span class="badge"
                                          [ngClass]="{'badge-gradient-green': this.paymentStatus === 'PAID','badge-gradient-red': this.paymentStatus !== 'PAID'}">{{ paymentStatus }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-bs-dismiss="modal" aria-label="Close"
                        (click)="postOrderDetailModal = false">Close
                </button>
            </div>
        </div>
    </div>
</div>
