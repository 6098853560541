<pf-dropdown class="user-menu" hide-arrow>
  <div slot="button">
    <a class="nav-link">
      <pf-image
        [src]="user.picture"
        alt="User Image"
        class="user-image-small"
        fallback-src="assets/img/default-profile.png"
        height="25"
        rounded
        width="25"
      ></pf-image>
    </a>
  </div>
  <div slot="menu">
    <li class="user-header bg-primary">
      <pf-image
        [src]="user.picture"
        alt="User Image"
        class="user-image-big"
        fallback-src="assets/img/default-profile.png"
        height="90"
        rounded
        width="90"
      ></pf-image>

      <!--<p>
        <span>{{ user.email }}</span>
        <small>
          <span class="mr-2">Member since</span>
          <span>{{ formatDate(user.createdAt) }}</span>
        </small>
      </p>-->
    </li>
    <!-- Menu Body -->
    <!--<li class="user-body">
      <div class="row">
        <div class="col-4 text-center">
          <a [routerLink]="['/']">Followers</a>
        </div>
        <div class="col-4 text-center">
          <a [routerLink]="['/']">Sales</a>
        </div>
        <div class="col-4 text-center">
          <a [routerLink]="['/']">Friends</a>
        </div>
      </div>
      &lt;!&ndash; /.row &ndash;&gt;
    </li>-->
    <!-- Menu Footer-->
    <li class="user-footer">
     <!-- <a [routerLink]="['/profile']" class="btn btn-default btn-flat">
        Profile
      </a>-->
      <a (click)="logout()" class="btn btn-danger btn-sm  float-right">
        Sign out
      </a>
    </li>
  </div>
</pf-dropdown>
