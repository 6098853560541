<section class="content">
    <div class="container-fluid col-lg-12 ml-1">
        <div class="row">
            <div class="row mt-3">
                <mat-card class="order-card">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-4 col-md-6 col-12 mt-3">
                                <div class="form-floating">
                                    <select class="form-select" id="floatingSelect"
                                            aria-label="Floating label select example"
                                            (change)="selectOrderStatus($event)">
                                        <option selected disabled>Select Order Status</option>
                                        <option *ngFor="let status of this.productOrderStatus;">{{status}}</option>
                                    </select>
                                    <label for="floatingSelect">Order Status</label>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-12 mt-3"></div>
                            <div class="col-lg-4 col-md-6 col-12 mt-3">
                                <div class="form-floating">
                                    <input type="search" class="form-control" placeholder="search"
                                           [(ngModel)]="searchText">
                                    <label class="text-gray">Search</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <mat-card-content>
                        <div class="container-fluid mt-3" type="button">
                            <div class="table-responsive table-style table-bordered">
                                <table class="table table-green table-head-fixed">
                                    <thead class="h6">
                                    <tr>
                                        <th>S.No</th>
                                        <th>Order ID</th>
                                        <th>Delivery Address</th>
                                        <th>Order Status</th>
                                        <th>Payment Status</th>
                                    </tr>
                                    </thead>
                                    <tbody class="text-center">
                                    <tr *ngFor="let product of this.tableProductOrders | filter: searchText; let i = index;"
                                        (click)="openModal(product.productOrderId)">
                                        <td>{{ i + 1 }}</td>
                                        <td>{{ product.productOrderUID }}</td>
                                        <td *ngIf="product.deliveryAddress != null">{{product.deliveryAddress.doorNo}}
                                            , {{product.deliveryAddress.streetName}}, {{product.deliveryAddress.area}}
                                            , {{product.deliveryAddress.city}}, {{product.deliveryAddress.state}}
                                            , {{product.deliveryAddress.pinCode}}</td>
                                        <td *ngIf="product.deliveryAddress == null"> -</td>
                                        <td><span class="badge"
                                                  [ngClass]="{'badge-gradient-info': product.productOrderStatus === 'Ordered', 'badge-gradient-red': product.productOrderStatus == 'Order Rejected', 'badge-gradient-green': product.productOrderStatus == 'Order Confirmed', 'badge-gradient-blue': product.productOrderStatus == 'Order Dispatched'}">{{ product.productOrderStatus }}</span>
                                        </td>
                                        <td><span class="badge"
                                                  [ngClass]="{'badge-gradient-green': product.paymentStatus === 'PAID','badge-gradient-red': product.paymentStatus !== 'PAID'}">{{ product.paymentStatus }}</span>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
    </div>
</section>

<div class="modal" tabindex="-1" [style.display]="orderDetailModal ? 'block' : 'none'"
     style="background-color: rgba(87,86,86,0.64)">
    <div class="modal-dialog modal-xl modal-dialog-scrollable">
        <div class="modal-content">
            <div class="modal-header modal-header-bg m-3 p-5">
                <h2 class="modal-title">Order #{{ orderID }}</h2>
                <span class="mt-2 fw-lighter">{{ orderDate }}</span>
                <!-- <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="orderDetailModal = false"></button>-->
            </div>
            <div class="modal-body">
                <div class="container-fluid mt-3">
                    <div class="table-responsive table-style table-bordered">
                        <table class="table table-green table-head-fixed">
                            <thead class="h6">
                            <tr>
                                <th>S.No</th>
                                <th>Product Name</th>
                                <th>Quantity</th>
                                <th>Rate</th>
                                <th>Amount</th>
                            </tr>
                            </thead>
                            <tbody class="text-center">
                            <tr *ngFor="let orders of this.orderList; let i = index;">
                                <td>{{ i + 1 }}</td>
                                <td>{{ orders.itemName}}</td>
                                <td>{{ orders.itemCount }}</td>
                                <td>{{ orders.itemSRP| currency:'INR':'' }}</td>
                                <td>{{orders.itemCount * orders.itemSRP | currency:'INR':''}}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="row mt-3">
                        <div class="col-lg-7">
                            <div class="row mt-3 p-3">
                                <mat-card class="order-card bg-light p-3">
                                    <mat-card-content>
                                        <div class="row">
                                            <div class="col-12">
                                                <span class="text-gray fw-bold">Customer Address Details</span>
                                            </div>
                                        </div>
                                        <div class="row mt-3">
                                            <div class="col-12">
                                                {{doorNo}}, {{streetName}},{{deliveryArea}},{{deliveryCity}}
                                                , {{deliveryState}}
                                                ,{{deliveryPinCode}}
                                            </div>
                                        </div>
                                    </mat-card-content>
                                </mat-card>
                            </div>
                        </div>
                        <div class="col-lg-1"></div>
                        <div class="col-lg-4">
                            <div class="row mt-3">
                                <div class="col-lg-4">Total</div>
                                <div class="col-lg-6 text-right">Rs.{{totalAmount | currency:'INR':''}}</div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-lg-4">Tax</div>
                                <div class="col-lg-6 text-right">Rs.{{tax | currency:'INR':''}}</div>
                            </div>
                            <hr>
                            <div class="row mt-3">
                                <div class="col-lg-4">Order Total</div>
                                <div class="col-lg-6 text-right">Rs.{{payableAmount | currency:'INR':''}}</div>
                            </div>
                            <hr>
                            <div class="row mt-3">
                                <div class="col-lg-5">Payment Status</div>
                                <div class="col-lg-6 text-right">
                                    <span class="badge"
                                          [ngClass]="{'badge-gradient-green': this.paymentStatus === 'PAID','badge-gradient-red': this.paymentStatus !== 'PAID'}">{{ paymentStatus }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-bs-dismiss="modal" aria-label="Close"
                        (click)="orderDetailModal = false">Close
                </button>
            </div>
        </div>
    </div>
</div>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" type="jelly-box">
    <div class="loader">
        <div class="inner one"></div>
        <div class="inner two"></div>
        <div class="inner three"></div>
    </div>
</ngx-spinner>