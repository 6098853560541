import {Component, OnInit, Renderer2} from "@angular/core";
import {ToastrService} from "ngx-toastr";
import {AppService} from "@services/app.service";
import {Router} from "@angular/router";
import {NgxSpinnerService} from "ngx-spinner";
import {ConfigService} from "@/config.service";
import {Subject, takeUntil, tap} from 'rxjs';
import {PaymentDetails} from "@/model/payment/PaymentDetails";
import {DatePipe} from '@angular/common';
import {LoggingService} from "@services/logging.service";
import {environment} from "../../../environments/environment";
import {Enquiry} from "@/model/enquiry/Enquiry";
import {val} from "dom7";

@Component({
    selector: 'app-enquiry',
    templateUrl: './enquiry.component.html',
    styleUrls: ['./enquiry.component.scss']
})
export class EnquiryComponent implements OnInit {
    destroy$: Subject<boolean> = new Subject<boolean>();
    searchText;
    enquiryDetailsList: Enquiry[] = [];
    allEnquiryDetailsList: Enquiry[] = [];
    enquiryStatus: String[] = [];
    enquiryStatusFilter: string | null = null;
    sellerName: String[] = [];
    sellerNameFilter: string | null = null;
    sellerUserType: String[] = [];
    sellerTypeFilter: string | null = null;
    enquirerName: String[] = [];
    enquirerNameFilter: string | null = null;
    enquirerType: String[] = [];
    enquirerTypeFilter: string | null = null;
    date: String[] = [];
    dateFilter: string | null = null;
    uniqueDates: string[] = [];
    isLocalhost = window.location.hostname === 'localhost';


    constructor(
        private renderer: Renderer2,
        private toastr: ToastrService,
        private appService: AppService,
        private router: Router,
        private spinner: NgxSpinnerService,
        private configService: ConfigService,
        private datePipe: DatePipe,
        private loggingService: LoggingService
    ) {
    }

    ngOnInit() {
        this.getAllEnquiries();
    }


    //Get all Enquiry Details

    getAllEnquiries() {
        this.spinner.show();
        this.appService.getAllEnquiryDetails()
            .pipe(takeUntil(this.destroy$),
                tap(
                    (enquiryData) => {
                        this.enquiryStatus = Array.from(new Set(enquiryData.map(status => status.enquiryStatus)));
                        this.sellerName = Array.from(new Set(enquiryData.map(sellerName => sellerName.product.userName)));
                        this.sellerUserType = Array.from(new Set(enquiryData.map(sellerUserType => sellerUserType.product.postedUserType)));
                        this.enquirerName = Array.from(new Set(enquiryData.map(name => name.userDetails.name)));
                        this.enquirerType = Array.from(new Set(enquiryData.map(type => type.userDetails.enrollmentType)));
                    }
                )
            )
            .subscribe(
                (enquiryDetails) => {
                    this.spinner.hide();
                    if (!environment.production && this.isLocalhost) {
                        console.log('Enquiry Details::', enquiryDetails);
                    }
                    this.enquiryDetailsList = enquiryDetails;
                    this.enquiryDetailsList.sort((a, b) => b.enquiryId - a.enquiryId);
                    this.allEnquiryDetailsList = this.enquiryDetailsList;
                }, (error: any) => {
                    this.toastr.error(error.error?.message || "Something went wrong, please try again")
                    console.log("error:", error.error?.message || "Something went wrong, please try again");
                    this.spinner.hide();
                },
                () => {
                    this.spinner.hide();
                }
            )
    }


    /*Enquiry Filter For sorting the table*/
    enquiryFilter(event: any, filterType: string) {
        const value = event.target.value;
        console.log(value);
        switch (filterType) {
            case 'enquiryStatus' :
                this.enquiryStatusFilter = value !== 'Select Enquiry Status' ? value : null;
                break;
            case 'sellerName' :
                this.sellerNameFilter = value !== 'Select Seller Name' ? value : null;
                break;
            case 'sellerType' :
                this.sellerTypeFilter = value !== 'Select Seller Type' ? value : null;
                break;
            case 'enquirerName' :
                this.enquirerNameFilter = value !== 'Select Enquirer Name' ? value : null;
                break;
            case 'enquirerType' :
                this.enquirerTypeFilter = value !== 'Select Enquirer Type' ? value : null;
                break;
            case 'date' :
                this.dateFilter = value !== 'Select Enquiry Date' ? value : null;
                break;
            default:
                break;
        }
        this.applyPaymentFilter();
    }


    // Apply Payment above the types of filters
    applyPaymentFilter() {
        const enquiryStatusFilter = this.enquiryStatusFilter ? this.enquiryDetailsList.filter(status => status.enquiryStatus === this.enquiryStatusFilter) : this.enquiryDetailsList;
        const sellerNameFilter = this.sellerNameFilter ? enquiryStatusFilter.filter(sellerName => sellerName.product.userName === this.sellerNameFilter) : enquiryStatusFilter;
        const dateFiltered = this.dateFilter
            ? sellerNameFilter.filter(date => {
                const options: Intl.DateTimeFormatOptions = {day: '2-digit', month: '2-digit', year: 'numeric'};
                const formattedDate = new Date(date.createdAt).toLocaleDateString('en-GB', options);
                return formattedDate === this.dateFilter;
            })
            : sellerNameFilter;
        const sellerTypeFilter = this.sellerTypeFilter ? dateFiltered.filter(sellerType => sellerType.product.postedUserType === this.sellerTypeFilter) : dateFiltered;
        const enquirerNameFilter = this.enquirerNameFilter ? sellerTypeFilter.filter(name => name.userDetails.name === this.enquirerNameFilter) : sellerTypeFilter;
        this.allEnquiryDetailsList = this.enquirerTypeFilter ? enquirerNameFilter.filter(type => type.userDetails.enrollmentType === this.enquirerTypeFilter) : enquirerNameFilter;
    }

    clearFilters() {
        this.enquiryStatusFilter = null;
        this.sellerNameFilter = null;
        this.sellerTypeFilter = null;
        this.enquirerNameFilter = null;
        this.enquirerTypeFilter = null;
        this.dateFilter = null;
        this.applyPaymentFilter();
    }

    getFormattedDate(createdAt: number): string {
        return this.datePipe.transform(createdAt, 'dd/MM/yyyy', 'IST', '');
    }

    isDuplicateDate(createdAt: number, index: number): boolean {
        // Check if the current date appears before (excluding) the current index
        return this.allEnquiryDetailsList.slice(0, index).some(payment => payment.createdAt === createdAt);
    }

}