<section class="content">
    <div class="container-fluid ml-lg-3 ml-2 mb-5">
        <div class="row">

            <div class="row mt-3">
                <div class="col-lg-4 col-md-4 col-12 mb-3">
                    <div class="form-floating">
                        <select class="form-select" id="floatingSelect"
                                aria-label="Floating label select example"
                                (change)="productFilter($event, 'productName')">
                            <option selected>Select Product Name</option>
                            <option value="All">All</option>
                            <option *ngFor="let name of this.productName">{{ name }}</option>
                        </select>
                        <label for="floatingSelect">Select Product Name</label>
                    </div>
                </div>
                <div class="col-lg-4 col-md-4 col-12 mb-3">
                    <div class="form-floating">
                        <select class="form-select"
                                aria-label="Floating label select example" (change)="productFilter($event, 'location')">
                            <option selected disabled>Select User Location</option>
                            <option value="All">All</option>
                            <option *ngFor="let location of this.productLocation">{{ location }}</option>
                        </select>
                        <label for="floatingSelect">Location</label>
                    </div>
                </div>
                <div class="col-lg-4 col-md-4 col-12 mb-3">
                    <div class="form-floating">
                        <input type="search" class="form-control" placeholder="search"
                               [(ngModel)]="searchText">
                        <label class="text-gray">Search</label>
                    </div>
                </div>
            </div>

            <div class="content-card mt-4 mb-4">
                <!--<mat-card class="col-lg-3 mb-2 mt-3 example-card" *ngFor="let product of this.productDetailsList">-->
                <mat-card class="items" *ngFor="let product of this.productsDetails | filter: searchText;">
                    <div class="d-flex flex-column h-100">
                        <mat-card-header>
                            <div mat-card-avatar class="example-header-image">
                                <h5 class="icon-circle"><h5
                                        class="fw-bolder text-uppercase text-center text-white mt-2">{{product.productName.charAt(0).toUpperCase()}}</h5>
                                </h5>
                            </div>
                            <mat-card-title class="fw-bold"><h6>{{product.productName}}</h6></mat-card-title>
                            <mat-card-subtitle class="text-black">{{product.friendlyName}}</mat-card-subtitle>
                        </mat-card-header>
                        <img mat-card-image class="flex-fill object-fit-contain"
                             *ngIf="product.productImageList?.length > 0 && product.productImageList[0].imageId"
                             [src]="endPoint + '/api/image-details/get/' + product.productImageList[0].imageId"
                             alt="image" loading="lazy">
                        <mat-card-content class="flex-grow-1">
                            <h6>{{product.userName}}</h6>
                            <p class="mb-2 text-gray">{{product.phoneNumber}}</p>
                        </mat-card-content>
                        <mat-card-actions class="text-end">
                            <button class="btn-inactive mr-md-4 mt-1" (click)="viewProfile(product.productId)">View
                            </button>
                            <span [ngClass]="{'badge-active': product.approvalStatus === 'Approved', 'badge-inactive': product.approvalStatus !== 'Approved'}"
                                  class="text-white mt-1">{{product.approvalStatus}}</span>
                        </mat-card-actions>
                    </div>
                </mat-card>
            </div>
        </div>
    </div>
</section>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" type="jelly-box">
    <div class="loader">
        <div class="inner one"></div>
        <div class="inner two"></div>
        <div class="inner three"></div>
    </div>
</ngx-spinner>