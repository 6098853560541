<section class="content">
    <div class="container-fluid ml-lg-3 ml-2 mb-5">
        <div class="row mb-5">
            <div class="col-lg-9">
                <h3 class="mt-3 mb-3">Post Details</h3>
                <div class="row mt-3">
                    <div class="row mt-2">
                        <div class="bg-white-detail col-12 col-lg-6 col-md-6 p-3">
                            <p class="mb-0">Post Name</p>
                            <span class="mt-0 mb-0 fw-bold">{{postName}}</span>
                        </div>
                    </div>
                    <!--<div class="row mt-2">
                        <div class="bg-white-detail col-12 col-lg-6 col-md-6 p-3">
                            <p class="mb-0">Availability Date</p>
                            <span class="mt-0 mb-0 fw-bold">{{availabilityDate}}</span>
                        </div>
                    </div>-->
                    <div class="row mt-2">
                        <div class="bg-white-detail col-12 col-lg-6 col-md-6 p-3">
                            <p class="mb-0">Approval Status</p>
                            <span class="mt-0 mb-0 fw-bold">{{isApproved}}</span>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="bg-white-detail col-12 col-lg-6 col-md-6 p-3">
                            <p class="mb-0">Phone Number</p>
                            <span class="mt-0 mb-0 fw-bold">{{phoneNo}}</span>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="bg-white-detail col-12 col-lg-6 col-md-6 p-3">
                            <p class="mb-0">Description</p>
                            <span class="mt-0 mb-0 fw-bold">{{description}}</span>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="bg-white-detail col-12 col-lg-6 col-md-6 p-3">
                            <p class="mb-0">Price</p>
                            <span class="mt-0 mb-0 fw-bold">RS.{{price}}</span>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="bg-white-detail col-12 col-lg-6 col-md-6 p-3">
                            <p class="mb-0">Tax</p>
                            <span class="mt-0 mb-0 fw-bold">{{tax}} %</span>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="bg-white-detail col-12 col-lg-6 col-md-6 p-3">
                            <p class="mb-0">Discount</p>
                            <span class="mt-0 mb-0 fw-bold">{{discount}} %</span>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="row mt-3">
                            <p class="mb-0 fw-bold">Post Images</p>
                        </div>
                        <div class="row mt-2 mb-2">
                            <div class="col-lg-2" *ngFor="let image of this.postImageList">
                                <img *ngIf="image?.imageId" src="{{endPoint}}/api/image-details/get/{{image.imageId}}"
                                     class="img-fluid" loading="lazy">
                            </div>
                        </div>
                    </div>

                    <div class="row mt-2 mb-5">
                        <div class="col-lg-6">
                            <table class="table bg-white-detail mb-5 table-borderless">
                                <thead>
                                <th>Feature Name</th>
                                <th>Friendly Name</th>
                                </thead>
                                <tbody>
                                <tr *ngFor="let feature of this.postFeatureList">
                                    <td>{{feature.featureName}}</td>
                                    <td>{{feature.friendlyName}}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 mt-lg-3 mt-5 mb-5">
                <div class="col-5 col-lg-7" *ngIf="isTrader == 'TRADER'">
                    <span class="badge-primary-blue btn-block text-center">Trader</span>
                </div>
                <div class="col-5 col-lg-7 mt-3" *ngIf="isApproved == 'Approved'">
                    <span class="badge-success-green btn-block">Approved</span>
                </div>
                <div *ngIf="isApproved != 'Approved'">
                    <div class="col-5 col-lg-7 mt-3">
                        <button class="btn-success-green btn-block" (click)="approveStatus('Approved', postId)">
                            Approve
                        </button>
                    </div>
                    <div class="col-5 col-lg-7 mt-3">
                        <button class="btn-danger-red btn-block" (click)="approveStatus('Not Approved', postId)">Reject
                        </button>
                    </div>
                    <div class="col-5 col-lg-7 mt-3">
                        <button class="btn-secondary-light btn-block" (click)="approveStatus('Hold', postId)">
                            Hold
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" type="jelly-box">
    <div class="loader">
        <div class="inner one"></div>
        <div class="inner two"></div>
        <div class="inner three"></div>
    </div>
</ngx-spinner>