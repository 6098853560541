import {Component, OnInit, Renderer2} from "@angular/core";
import {ToastrService} from "ngx-toastr";
import {AppService} from "@services/app.service";
import {Router} from "@angular/router";
import {NgxSpinnerService} from "ngx-spinner";
import {ConfigService} from "@/config.service";
import {Subject, takeUntil, tap} from 'rxjs';
import {PaymentDetails} from "@/model/payment/PaymentDetails";
import {DatePipe} from '@angular/common';
import {LoggingService} from "@services/logging.service";
import {environment} from "../../../environments/environment";

@Component({
    selector: 'app-orders',
    templateUrl: './payment.component.html',
    styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {
    destroy$: Subject<boolean> = new Subject<boolean>();
    searchText;
    paymentDetailsList: PaymentDetails[] = [];
    paymentList: PaymentDetails[] = [];
    paymentStatus: String[] = [];
    paymentStatusFilter: string | null = null;
    paymentType: String[] = [];
    paymentTypeFilter: string | null = null;
    date: String[] = [];
    dateFilter: string | null = null;
    uniqueDates: string[] = [];
    isLocalhost = window.location.hostname === 'localhost';


    constructor(
        private renderer: Renderer2,
        private toastr: ToastrService,
        private appService: AppService,
        private router: Router,
        private spinner: NgxSpinnerService,
        private configService: ConfigService,
        private datePipe: DatePipe,
        private loggingService: LoggingService
    ) {
    }

    ngOnInit() {
        this.getAllPaymentDetails();
    }


    //Get all Payment Details
    getAllPaymentDetails() {
        this.spinner.show();
        this.appService.getAllPayments()
            .pipe(takeUntil(this.destroy$),
                tap(data => {
                    this.spinner.hide();
                    this.paymentStatus = Array.from(new Set(data.map(status => status.paymentStatus)));
                    if (!environment.production && this.isLocalhost) {
                        console.log('Payment Status::', this.paymentStatus);
                    }
                    this.paymentType = Array.from(new Set(data.map(type => type.referenceType)));
                    const uniqueDates = Array.from(new Set(data.map(item => item.createdAt)));
                    const normalDate = uniqueDates.map(epoch =>
                        this.datePipe.transform(epoch, 'dd/MM/yyyy')
                    );
                    this.uniqueDates = Array.from(new Set(normalDate));
                    if (!environment.production && this.isLocalhost) {
                        console.log('unique Date', this.uniqueDates);
                    }
                })
            )
            .subscribe(
                (paymentList) => {
                    this.spinner.hide();
                    if (!environment.production && this.isLocalhost) {
                        console.log('payment list::', paymentList);
                    }
                    this.paymentDetailsList = paymentList;
                    this.paymentDetailsList.sort((a, b) => b.paymentId - a.paymentId);
                    this.paymentList = this.paymentDetailsList;
                },
                (error: any) => {
                    console.log("error:", error.error?.message || "Something went wrong, please try again");
                    this.spinner.hide();
                },
                () => {
                    this.spinner.hide();
                }
            );
    }


    /*Payment Filter For sorting the table*/
    paymentFilter(event: any, filterType: string) {
        const value = event.target.value;

        switch (filterType) {
            case 'status' :
                this.paymentStatusFilter = value !== 'Select Payment Status' ? value : null;
                break;
            case 'paymentType' :
                this.paymentTypeFilter = value !== 'Select Payment Type' ? value : null;
                break;
            case 'date' :
                this.dateFilter = value !== 'Select Payment Date' ? value : null;
                break;
            default:
                break;
        }
        this.applyPaymentFilter();
    }


    // Apply Payment above the types of filters
    applyPaymentFilter() {
        const paymentFilter = this.paymentStatusFilter ? this.paymentDetailsList.filter(status => status.paymentStatus === this.paymentStatusFilter) : this.paymentDetailsList;
        const typeFilter = this.paymentTypeFilter ? paymentFilter.filter(type => type.referenceType === this.paymentTypeFilter) : paymentFilter;
        this.paymentList = this.dateFilter
            ? typeFilter.filter(date => {
                const options: Intl.DateTimeFormatOptions = {day: '2-digit', month: '2-digit', year: 'numeric'};
                const formattedDate = new Date(date.createdAt).toLocaleDateString('en-GB', options);
                return formattedDate === this.dateFilter;
            })
            : typeFilter;
    }

    clearFilters() {
        this.paymentStatusFilter = 'Select Payment Status' && null;
        this.paymentTypeFilter = 'Select Payment Type' && null;
        this.dateFilter = 'Select Payment Date' && null;
        this.applyPaymentFilter();
    }

    getFormattedDate(createdAt: number): string {
        return this.datePipe.transform(createdAt, 'dd/MM/yyyy', 'IST', '');
    }

    isDuplicateDate(createdAt: number, index: number): boolean {
        // Check if the current date appears before (excluding) the current index
        return this.paymentList.slice(0, index).some(payment => payment.createdAt === createdAt);
    }

}