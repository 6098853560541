import {Component, HostBinding, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators, FormsModule} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {AppService} from '@services/app.service';
import {Subject, takeUntil} from 'rxjs';
import Swal from 'sweetalert2'
import {NgxSpinnerService} from "ngx-spinner";
import {Router} from "@angular/router";
import {LoginDetails, LoginUserDetails} from "@/model/login/LoginDetails";
import {countryOptions} from '@/model/country-code/CountryCode';
import {environment} from "../../../environments/environment";


@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    destroy$: Subject<boolean> = new Subject<boolean>();
    /* @HostBinding('class') class = 'login-box';*/
    /*public loginForm: UntypedFormGroup;*/
    public isAuthLoading = false;
    public isGoogleLoading = false;
    public isFacebookLoading = false;
    openOTPModal = false;
    buttonShow = true;
    firstValue: string = '';
    secondValue: string = '';
    thirdValue: string = '';
    fourthValue: string = '';
    phoneNumber: string;
    loginDetails: LoginDetails | undefined;
    options = countryOptions;
    buttonClicked = false;
    isLocalhost = window.location.hostname === 'localhost';

    constructor(
        private renderer: Renderer2,
        private toastr: ToastrService,
        private appService: AppService,
        private spinner: NgxSpinnerService,
        private router: Router
    ) {
    }

    ngOnInit() {
        /* this.renderer.addClass(
           document.querySelector('app-root'),
           'login-page'
         );*/
        /*If already login Once but not Logout No need to Login screen go through user management Screen*/
        const token = localStorage.getItem('authToken');
        const type = localStorage.getItem('userType');
        if (token != undefined && type != undefined && type == 'Admin') {
            this.router.navigate(['./main/user-management']);
        } else {
            this.router.navigate(['']);
        }
    }

    // Login Form Group
    loginForm = new UntypedFormGroup({
        countryCode: new UntypedFormControl("91", [
            Validators.required,
            Validators.nullValidator]),
        phoneNumber: new UntypedFormControl("", [
            Validators.required,
            Validators.nullValidator, Validators.nullValidator, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")
        ]),
        otp: new UntypedFormControl("", [
            Validators.required,
            Validators.nullValidator, Validators.nullValidator, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")
        ]),
    });

    public loginErrors = {
        countryCode: '',
        phoneNumber: '',
    }


    // Login Validation is any Mandatory fields is empty
    loginValidation() {
        this.loginErrors.countryCode = "";
        this.loginErrors.phoneNumber = "";
        let hasError = false;

        if (this.loginForm.get("countryCode")?.invalid) {
            this.loginErrors.countryCode = "Select Country Code";
            hasError = true;
        }
        if (this.loginForm.get('phoneNumber')?.invalid) {
            if (this.loginForm.get('phoneNumber').errors.required) {
                this.loginErrors.phoneNumber = "Phone Number is required";
            }
            if (this.loginForm.get('phoneNumber').errors.pattern) {
                this.loginErrors.phoneNumber = "Please, Enter 10 digit Valid Phone Number.";
            }
            hasError = true;
        }
        if (!hasError) {
            this.sendOTPToPhoneNumber();
        }
    }

    onSubmit() {
        this.buttonClicked = true;
        this.loginValidation()
    }

    /*Only Entered Number input Field in Phone Number*/
    OnlyNumbers($event) {
        let regex: RegExp = new RegExp(/^[0-9]{1,}$/g);
        let specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'ArrowRight', 'ArrowLeft'];
        if (specialKeys.indexOf($event.key) !== -1) {
            return;
        } else {
            if (regex.test($event.key)) {
                return true;
            } else {
                return false;
            }
        }
    }

    // OTP Input box auto move focus input field
    moveFocus(e: any, p: any, c: any, n: any) {
        var length = c.value.length;
        var maxlength = c.getAttribute('maxlength');
        if (length == maxlength) {
            if (n != "") {
                n.focus();
            }
        }
        if (e.key == "Backspace") {
            if (p != "") {
                p.focus();
            }
        }
    }

    async loginByAuth() {
        if (this.loginForm.valid) {
            this.isAuthLoading = true;
            await this.appService.loginByAuth(this.loginForm.value);
            this.isAuthLoading = false;
        } else {
            this.toastr.error('Form is not valid!');
        }
    }

    async loginByGoogle() {
        this.isGoogleLoading = true;
        await this.appService.loginByGoogle();
        this.isGoogleLoading = false;
    }

    async loginByFacebook() {
        this.isFacebookLoading = true;
        await this.appService.loginByFacebook();
        this.isFacebookLoading = false;
    }


    /*Send OTP to ADMIN Phone number*/
    sendOTPToPhoneNumber() {
        /*const phoneNumber = {
            phoneNumber: this.loginForm.get('countryCode').value + this.loginForm.get('phoneNumber').value
        }*/

        this.phoneNumber = this.loginForm.get('phoneNumber').value;
        this.spinner.show();
        this.appService
            .sendOtpVerify(this.loginForm.value)
            .pipe(takeUntil(this.destroy$))
            .subscribe(data => {
                    this.spinner.hide();
                    if (!environment.production && this.isLocalhost) {
                        console.log("Data::", data);
                    }
                    this.openOTPModal = true;
                    this.buttonShow = false;
                    this.loginForm.get('countryCode').disable();
                    this.loginForm.get('phoneNumber').disable();
                },
                (error: any) => {
                    this.buttonShow = true;
                    if (!environment.production && this.isLocalhost) {
                        console.log("error:", error);
                    }
                    this.spinner.hide();
                    if (error && error.error && error.error.msg) {
                        this.buttonShow = true;
                        this.spinner.hide();
                        this.toastr.error(error.error.msg);
                    } else {
                        this.buttonShow = true;
                        this.spinner.hide();
                        this.toastr.error("Something went wrong, please try again");
                    }
                });
    }

    /*After Send OTP Phone number OTP Verification to confirm Login*/
    verifyOtp() {
        let hasError = false;

        if (this.firstValue == "") {
            this.toastr.error("Please enter OTP")
            hasError = true;
        }
        if (this.secondValue == "") {
            this.toastr.error("Please enter OTP")
            hasError = true;
        }
        if (this.thirdValue == "") {
            this.toastr.error("Please enter OTP")
            hasError = true;
        }
        if (this.fourthValue == "") {
            this.toastr.error("Please enter OTP")
            hasError = true;
        }
        if (!hasError) {
            this.spinner.show();
            const otp = this.firstValue + this.secondValue + this.thirdValue + this.fourthValue
            const verifyOtp = {
                countryCode: this.loginForm.get('countryCode').value,
                phoneNumber: this.loginForm.get('phoneNumber').value,
                otp: otp,
            }
            this.appService.verifyOtp(verifyOtp)
                .pipe(takeUntil(this.destroy$))
                .subscribe(data => {
                    this.spinner.hide();
                    if (!environment.production && this.isLocalhost) {
                        console.log("OTP Verified::", data);
                    }
                    this.buttonShow = true;
                    this.loginDetails = data;
                    localStorage.setItem('authToken', this.loginDetails.authHeader);
                    localStorage.setItem('userType', this.loginDetails.login.userType);
                    localStorage.setItem('loginId', String(this.loginDetails.login.loginId));
                    this.router.navigate(['./main/user-management']);
                    this.spinner.hide();
                    this.openOTPModal = false;
                    if (this.loginDetails.login.userType == 'Admin') {
                        this.toastr.success('OTP Verified, Welcome to E Shed');
                    } else {
                        this.toastr.error('Access Denied');
                    }
                }, (error: any) => {
                    console.error("Error:", error);
                    this.spinner.hide();

                    const errorMessage = error?.error?.message || "Something went wrong, please try again";
                    this.toastr.error(errorMessage);
                });
        }
    }


    /*ngOnDestroy() {
      this.renderer.removeClass(
        document.querySelector('app-root'),
        'login-page'
      );
    }*/
}
