import {Injectable} from "@angular/core";
import {HttpHeaders} from "@angular/common/http";

@Injectable({
    providedIn: 'root'
})
export class LocalDataService {

    //Authorization: Basic c3RyaW5nOnN0cmluZw==
    getHeaders() {
        return new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.getToken(),
            'Access-Control-Allow-Origin': '*',
            /*'APP_FLAVOUR': 'AFTC'*/
            /* 'APP_FLAVOUR': localStorage.getItem("selectedWebsiteName"),*/
        });
    }

    getHeadersWithoutToken() {
        return new HttpHeaders({
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            /*'APP_FLAVOUR': 'AFTC'*/
            /*'APP_FLAVOUR': localStorage.getItem("selectedWebsiteName"),*/
        });
    }

    getPDFHeader() {
        return new HttpHeaders({
            'Content-Type': 'application/pdf',
            'Access-Control-Allow-Origin': '*',
            /*'APP_FLAVOUR': 'AFTC'*/
            /*'APP_FLAVOUR': localStorage.getItem("selectedWebsiteName"),*/
        });
    }

    getImageHeaders() {
        return new HttpHeaders({
            'Authorization': 'Bearer ' + this.getToken(),
            'Access-Control-Allow-Origin': '*',
            /*'APP_FLAVOUR': localStorage.getItem("selectedWebsiteName"),*/
        });
    }

    getSiteHeaders() {
        return new HttpHeaders({
            'Authorization': 'Bearer ' + this.getToken(),
            'Access-Control-Allow-Origin': '*',
        });
    }


    /* signinHeaders() {
         return new HttpHeaders({
             'Content-Type': 'application/json',
             'Access-Control-Allow-Origin': '*',
             'APP_FLAVOUR': 'AFTC',
             'accept': '*!/!*'
         });
     }*/

    getToken() {
        return localStorage.getItem("authToken")
    }
}