import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

@Injectable({
    providedIn: 'root'
})
export class LoggingService {
    log(message: string, data?: any) {
        const isLocalhost = window.location.hostname === 'localhost';
        if (!environment.production && isLocalhost) {
            console.log(message, data);
        }
    }
}