<section class="content">
    <div class="container-fluid col-lg-3-12 ml-1">
        <div class="row">
            <div class="row mt-3">
                <mat-card class="order-card">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-lg-3 col-md-6 col-12 mt-2">
                                <div class="form-floating">
                                    <select class="form-select" id="floatingSelect"
                                            aria-label="Floating label select example" [(ngModel)]="enquiryStatusFilter"
                                            (change)="enquiryFilter($event, 'enquiryStatus')">
                                        <option selected value="">Select Enquiry Status</option>
                                        <option *ngFor="let status of this.enquiryStatus">{{ status }}</option>
                                    </select>
                                    <label for="floatingSelect">Enquiry Status</label>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-6 col-12 mt-2">
                                <div class="form-floating">
                                    <select class="form-select"
                                            aria-label="Floating label select example" [(ngModel)]="sellerNameFilter"
                                            (change)="enquiryFilter($event, 'sellerName')">
                                        <option selected value="">Select Seller Name</option>
                                        <option *ngFor="let name of this.sellerName">{{ name }}</option>
                                    </select>
                                    <label for="floatingSelect">Seller Name</label>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-6 col-12 mt-2">
                                <div class="form-floating">
                                    <select class="form-select"
                                            aria-label="Floating label select example" [(ngModel)]="sellerTypeFilter"
                                            (change)="enquiryFilter($event, 'sellerType')">
                                        <option selected value="">Select Seller Type</option>
                                        <option *ngFor="let userType of this.sellerUserType">{{ userType }}</option>
                                    </select>
                                    <label for="floatingSelect">Seller Type</label>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-6 col-12 mt-2">
                                <div class="form-floating">
                                    <select class="form-select"
                                            aria-label="Floating label select example" [(ngModel)]="enquirerNameFilter"
                                            (change)="enquiryFilter($event, 'enquirerName')">
                                        <option selected value="">Select Enquire Name</option>
                                        <option *ngFor="let name of this.enquirerName">{{ name }}</option>
                                    </select>
                                    <label for="floatingSelect">Enquire Name</label>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-6 col-12 mt-2">
                                <div class="form-floating">
                                    <select class="form-select"
                                            aria-label="Floating label select example" [(ngModel)]="enquirerTypeFilter"
                                            (change)="enquiryFilter($event, 'enquirerType')">
                                        <option value="" selected>Select Enquire Type</option>
                                        <option *ngFor="let enquiryType of this.enquirerType">{{ enquiryType }}</option>
                                    </select>
                                    <label for="floatingSelect">Enquire Type</label>
                                </div>
                            </div>
                            <div class="col-lg-3 mt-2 align-self-center align-items-end">
                                <button class="btn btn-danger mt-2" (click)="clearFilters()">Clear Filters <img
                                        class="img-fluid" alt="clear" src="assets/img/clear-alt.png"></button>
                            </div>
                            <div class="col-lg-6 mt-2">
                                <div class="form-floating">
                                    <input type="search" class="form-control" placeholder="search"
                                           [(ngModel)]="searchText">
                                    <label class="text-gray">Search</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <mat-card-content>
                        <div class="container-fluid mt-3">
                            <div class="table-responsive table-style table-bordered">
                                <table class="table table-green table-head-fixed">
                                    <thead class="h6">
                                    <tr>
                                        <th>S.No</th>
                                        <th>Enquirer Name</th>
                                        <th>Enquirer Phone Number</th>
                                        <th>Enquirer Type</th>
                                        <th>Seller Name</th>
                                        <th>Seller Phone Number</th>
                                        <th>Seller Type</th>
                                        <th>Enquiry Date</th>
                                        <th>Enquiry Status</th>
                                    </tr>
                                    </thead>
                                    <tbody class="text-center"
                                           *ngIf="this.allEnquiryDetailsList.length > 0; else noDataFound">
                                    <tr *ngFor="let enquiry of this.allEnquiryDetailsList | filter: searchText; let i = index;">
                                        <td>{{ i + 1 }}</td>
                                        <td>{{ enquiry.userDetails.name}}</td>
                                        <td>{{ enquiry.userDetails.phoneNumber}}</td>
                                        <td>{{ enquiry.userDetails.enrollmentType}}</td>
                                        <td>{{enquiry.product.userName}}</td>
                                        <td>{{enquiry.product.phoneNumber}}</td>
                                        <td>{{enquiry.product.postedUserType}}</td>
                                        <td *ngIf="!isDuplicateDate(enquiry.createdAt, i)">
                                            {{ getFormattedDate(enquiry.createdAt) }}
                                        </td>
                                        <td>
                                            <span class="badge"
                                                  [ngClass]="{'badge-gradient-green': enquiry.enquiryStatus === 'OPEN' , 'badge-gradient-red': enquiry.enquiryStatus === 'CLOSED'}">{{ enquiry.enquiryStatus }}</span>
                                        </td>
                                    </tr>
                                    </tbody>
                                    <ng-template #noDataFound>
                                        <tr>
                                            <td colspan="12" class="text-center text-danger">Data Not Found</td>
                                        </tr>
                                    </ng-template>
                                    <ng-template #noDataFoundSearch>
                                        <tr>
                                            <td colspan="12" class="text-center text-danger">Data Not Found</td>
                                        </tr>
                                    </ng-template>
                                </table>
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
    </div>
</section>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" type="jelly-box">
    <div class="loader">
        <div class="inner one"></div>
        <div class="inner two"></div>
        <div class="inner three"></div>
    </div>
</ngx-spinner>