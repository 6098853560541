<section class="content">
    <div class="container-fluid ml-lg-3 ml-2 mb-5">
        <div class="row">
            <div class="col-lg-12">
                <div class="row">
                    <div class="row mb-3">
                        <div class="col-lg-3 col-md-5 mt-2">
                            <h3 class="fw-bolder mt-4">Personal Details</h3>
                        </div>
                        <div class="col-lg-5 col-md-7 mt-2">
                            <div>
                                <div class="row" *ngIf="isActive != 'ACTIVE'">
                                    <div class="col-md-4 col-lg-4 mt-3">
                                        <button class="btn-success-green btn-block p-3"
                                                (click)="approveStatus('VERIFIED', regId)">
                                            Approve
                                        </button>
                                    </div>
                                    <div class="col-md-4 col-lg-4 mt-3">
                                        <button class="btn-danger-red btn-block p-3"
                                                (click)="approveStatus('REJECTED', regId)">Reject
                                        </button>
                                    </div>
                                    <div class="col-md-4 col-lg-4 mt-3">
                                        <button class="btn-secondary-light btn-block p-3"
                                                (click)="approveStatus('NON_VERIFIED', regId)">Hold
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-12 mt-2">
                            <div class="row">
                                <div class="col-md-3 col-lg-4 mt-3">
                                    <span class="badge-primary-blue btn-block text-center"
                                          *ngIf="isTrader == 'TRADERS'">Trader</span>
                                </div>
                                <div class="col-md-3 col-lg-4 mt-3" *ngIf="this.paymentStatus != null">
                                    <span class="badge-primary-blue btn-block text-center p-3">{{paymentStatus}}</span>
                                </div>
                                <div class="col-md-3 col-lg-4 mt-3" *ngIf="isActive == 'ACTIVE'">
                                    <span class="badge-success-green text-center btn-block p-3">Approved</span>
                                </div>
                            </div>
                        </div>
                        <!--<div class="col-lg-5 col-md-12 mt-2">
                            <div class="row" *ngIf="isActive != 'ACTIVE'">
                                <div class="col-md-3 col-lg-3 mt-3">
                                    <button class="btn-success-green btn-block p-3"
                                            (click)="approveStatus('VERIFIED', regId)">
                                        Approve
                                    </button>
                                </div>
                                <div class="col-md-3 col-lg-3 mt-3">
                                    <button class="btn-danger-red btn-block p-3"
                                            (click)="approveStatus('REJECTED', regId)">Reject
                                    </button>
                                </div>
                                <div class="col-md-3 col-lg-3 mt-3">
                                    <button class="btn-secondary-light btn-block p-3"
                                            (click)="approveStatus('NON_VERIFIED', regId)">Hold
                                    </button>
                                </div>
                            </div>
                        </div>-->
                        <!--<div class="col-lg-5 col-md-12 mt-2">
                            <div class="row">
                                <div class="col-md-4 col-lg-4 mt-3">
                                    <span class="badge-primary-blue btn-block text-center"
                                          *ngIf="isTrader == 'TRADERS'">Trader</span>
                                </div>
                                <div class="col-md-4 col-lg-4 mt-3" *ngIf="this.paymentStatus != null">
                                    <span class="badge-primary-blue btn-block text-center">{{paymentStatus}}</span>
                                </div>
                                <div class="col-md-4 col-lg-4 mt-3" *ngIf="isActive == 'ACTIVE'">
                                    <span class="badge-success-green text-center btn-block">Approved</span>
                                </div>
                            </div>
                        </div>-->
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-lg-8 mt-2">
                        <div class="row">
                            <div class="col-12 col-lg-4 col-md-6 mt-2">
                                <div class="bg-white-detail p-3">
                                    <p class="mb-0">Name</p>
                                    <span class="mt-0 fw-bold">{{name}}</span>
                                </div>
                            </div>
                            <div class="col-12 col-lg-4 col-md-6 mt-2">
                                <div class="bg-white-detail p-3">
                                    <p class="mb-0">Phone Number</p>
                                    <span class="mt-0 fw-bold">{{phoneNo}}</span>
                                </div>
                            </div>

                            <div class="col-12 col-lg-4 col-md-6 mt-2">
                                <div class="bg-white-detail p-3">
                                    <p class="mb-0">Email</p>
                                    <span class="mt-0 fw-bold">{{emailId}}</span>
                                </div>
                            </div>

                            <div class="col-12 col-lg-4 col-md-6 mt-2">
                                <div class="bg-white-detail p-3">
                                    <p class="mb-0">Self Description</p>
                                    <span class="mt-0 fw-bold">{{description}}</span>
                                </div>
                            </div>
                            <div class="row mt-4 ml-2" *ngIf="traderDetail">
                                <div class="col-12">
                                    <h4 class="fw-bolder">Trader Details</h4>
                                </div>
                            </div>
                            <div class="row" *ngIf="traderDetail">
                                <div class="col-12 col-lg-4 col-md-6 mt-2">
                                    <div class="bg-white-detail p-3">
                                        <p class="mb-0">Company Name</p>
                                        <span class="mt-0 fw-bold">{{companyName}}</span>
                                    </div>
                                </div>
                                <div class="col-12 col-lg-4 col-md-6 mt-2">
                                    <div class="bg-white-detail p-3">
                                        <p class="mb-0">Company Name</p>
                                        <span class="mt-0 fw-bold">{{companyName}}</span>
                                    </div>
                                </div>

                                <div class="col-12 col-lg-4 col-md-6 mt-2">
                                    <div class="bg-white-detail p-3">
                                        <p class="mb-0">Registered Address</p>
                                        <span class="mt-0 fw-bold">{{displayAddress}}</span>
                                    </div>
                                </div>

                                <div class="col-12 col-lg-4 col-md-6 mt-2">
                                    <div class="bg-white-detail p-3">
                                        <p class="mb-0">Display Address</p>
                                        <span class="mt-0 fw-bold">{{displayAddress}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 mt-2">
                        <div class="row mt-2">
                            <div class="row mt-2 mb-1">
                                <p class="mb-0">Validation Proof</p>
                            </div>
                            <div class="row mt-3 mb-3">
                                <div class="col-lg-4 col-6 col-md-4 mt-2"
                                     *ngFor="let image of this.verificationImageList">
                                    <img *ngIf="image?.imageId"
                                         src="{{this.endPoint}}/api/image-details/get/{{image.imageId}}"
                                         class="img-fluid"
                                         loading="lazy" (click)="openModal(image)">
                                </div>
                            </div>
                        </div>

                        <div class="row mt-2" *ngIf="traderDetail">
                            <div class="row mt-2 mb-1">
                                <p class="mb-0">Trader Pictures</p>
                            </div>
                            <div class="row mt-3 mb-3">
                                <div class="col-lg-4 col-6 col-md-4 mt-2" *ngFor="let image of this.traderImageList">
                                    <img *ngIf="image?.imageId"
                                         src="{{endPoint}}/api/image-details/get/{{image.imageId}}" class="img-fluid"
                                         loading="lazy" (click)="openModal(image)">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!--<div class="row" *ngIf="traderDetail">
                    <div class="col-lg-12">
                        <h4 class="fw-bolder mt-2 mb-2">Trader Details</h4>
                        <div class="row mt-3">
                            <div class="row">
                                <div class="bg-white-detail col-12 col-lg-6 col-md-6 p-3">
                                    <p class="mb-0">Company Name</p>
                                    <span class="mt-0 fw-bold">{{companyName}}</span>
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="bg-white-detail col-12 col-lg-6 col-md-6 p-3">
                                    <p class="mb-0">About the Business</p>
                                    <span class="mt-0 fw-bold">{{aboutBusiness}}</span>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="row mt-2">
                                    <p class="mb-2">Address</p>
                                    <div class="bg-white-detail col-12 col-lg-6 col-md-6 p-3">
                                        <p class="mb-0">Registered Address</p>
                                        <span class="mt-0 fw-bold">{{displayAddress}}</span>
                                    </div>
                                </div>
                                <div class="row mt-2">
                                    <div class="bg-white-detail col-12 col-lg-6 col-md-6 p-3">
                                        <p class="mb-0">Display Address</p>
                                        <span class="mt-0 fw-bold">{{displayAddress}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-12 col-lg-6 col-md-6">
                                    <p class="fw-bold">Trader Pictures</p>
                                </div>
                            </div>
                            <div class="row mt-3 mb-5">
                                <div class="col-lg-2 col-6 col-md-4" *ngFor="let image of this.traderImageList">
                                    <img *ngIf="image?.imageId"
                                         src="{{endPoint}}/api/image-details/get/{{image.imageId}}" class="img-fluid"
                                         loading="lazy">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>-->
            </div>
        </div>
        <div class="container-fluid" *ngIf="userProductsList.length > 0">
            <div class="row mb-3">
                <div class="col-12">
                    <h4 class="fw-bolder mt-2 mb-2">{{name}} Products</h4>
                </div>
            </div>
            <div class="row">
                <div class="content-card mb-4">
                    <!--<mat-card class="col-lg-3 mb-2 mt-3 example-card" *ngFor="let product of this.productDetailsList">-->
                    <mat-card class="items" *ngFor="let product of this.userProductsList">
                        <div class="d-flex flex-column h-100">
                            <mat-card-header>
                                <div mat-card-avatar class="example-header-image">
                                    <h5 class="icon-circle"><h5
                                            class="fw-bolder text-uppercase text-center text-white mt-2">{{product.productName.charAt(0).toUpperCase()}}</h5>
                                    </h5>
                                </div>
                                <mat-card-title class="fw-bold"><h6>{{product.productName}}</h6></mat-card-title>
                                <mat-card-subtitle class="text-black">{{product.friendlyName}}</mat-card-subtitle>
                            </mat-card-header>
                            <img mat-card-image class="flex-grow-1 object-fit-contain"
                                 *ngIf="product.productImageList?.length > 0 && product.productImageList[0].imageId"
                                 [src]="endPoint + '/api/image-details/get/' + product.productImageList[0].imageId"
                                 alt="image" loading="lazy">
                            <!-- <mat-card-content class="flex-grow-1">

                             </mat-card-content>
                             <mat-card-actions>
                                &lt;!&ndash; <button class="btn-inactive mr-md-4 mt-1" (click)="viewProfile(product.productId)">View</button>
                                 <span [ngClass]="{'badge-active': product.approvalStatus === 'VERIFIED', 'badge-inactive': product.approvalStatus !== 'VERIFIED'}"
                                       class="text-white mt-1">{{product.approvalStatus}}</span>&ndash;&gt;
                             </mat-card-actions>-->
                            <mat-card-actions>

                            </mat-card-actions>
                        </div>
                    </mat-card>
                </div>
            </div>
        </div>

       <!-- <div class="container-fluid mt-3" *ngIf="userPostList.length > 0">
            <div class="row">
                <div class="col-12">
                    <h4 class="fw-bolder mt-2">{{name}} Posts</h4>
                </div>
            </div>
            <div class="row">
                <div class="content-card mt-4 mb-4">
                    &lt;!&ndash;<mat-card class="col-lg-3 mb-3 mt-3 example-card" *ngFor="let post of this.postDetailsList">&ndash;&gt;
                    <mat-card class="items" *ngFor="let post of this.userPostList">
                        <div class="d-flex flex-column h-100">
                            <mat-card-header>
                                <div mat-card-avatar class="example-header-image">
                                    <h5 class="icon-circle"><h5
                                            class="fw-bolder text-uppercase text-center text-white mt-2">{{post.postName.charAt(0).toUpperCase()}}</h5>
                                    </h5>
                                </div>
                                <mat-card-title class="fw-bold"><h6>{{post.postName}}</h6></mat-card-title>
                                <mat-card-subtitle class="text-black">{{post.friendlyName}}</mat-card-subtitle>
                            </mat-card-header>
                            <img mat-card-image class="flex-grow-1 object-fit-contain"
                                 *ngIf="post.postImageList?.length > 0 && post.postImageList[0].imageId"
                                 [src]="endPoint + '/api/image-details/get/' + post.postImageList[0].imageId"
                                 alt="image" loading="lazy">
                            <mat-card-content class="flex-grow-1">
                                <h6 class="mb-2">{{post.userName}}</h6>
                                <p class="mb-2 text-gray">{{post.createdBy}}</p>
                            </mat-card-content>
                        </div>
                    </mat-card>
                </div>
            </div>
        </div>-->
    </div>
</section>


<div class="modal" id="imageModal" tabindex="-1" role="dialog" aria-labelledby="imageModalLabel" aria-hidden="true" [style.display]="imageModalShow ? 'block' : 'none'" style="background-color: rgba(87,86,86,0.64)">
    <div class="modal-dialog modal-lg modal-dialog-scrollable" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="imageModalLabel">Image Preview</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="imageModalShow = false">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-12 text-center">
                        <img [src]="selectedImageUrl" class="img-fluid">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" type="jelly-box">
    <div class="loader">
        <div class="inner one"></div>
        <div class="inner two"></div>
        <div class="inner three"></div>
    </div>
</ngx-spinner>