import {AppState} from '@/store/state';
import {UiState} from '@/store/ui/state';
import {Component, HostBinding, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppService} from '@services/app.service';
import {Observable} from 'rxjs';

const BASE_CLASSES = 'main-sidebar elevation-4';

@Component({
  selector: 'app-menu-sidebar',
  templateUrl: './menu-sidebar.component.html',
  styleUrls: ['./menu-sidebar.component.scss']
})
export class MenuSidebarComponent implements OnInit {
  @HostBinding('class') classes: string = BASE_CLASSES;
  public ui: Observable<UiState>;
  public user;
  public menu = MENU;

  constructor(
    public appService: AppService,
    private store: Store<AppState>
  ) {
  }

  ngOnInit() {
    this.ui = this.store.select('ui');
    this.ui.subscribe((state: UiState) => {
      this.classes = `${BASE_CLASSES} ${state.sidebarSkin}`;
    });
    this.user = this.appService.user;
  }
}

export const MENU = [
  /*{
    name: 'Dashboard',
    iconClasses: 'fas fa-tachometer-alt',
    path: ['/']
  },*/
  {
    name: 'User Management',
    iconClasses: 'fas fa-user',
    path: ['/main/user-management']
  },
  {
    name: 'Product',
    iconClasses: 'fas fa-shopping-cart',
    path: ['/main/product']
  },
  /*{
    name: 'Post',
    iconClasses: 'fas fa fa-images',
    path: ['/main/post']
  },*/
  {
    name: 'Orders',
    iconClasses: 'fas fa-bell',
    children: [
      {
        name: 'Product',
        iconClasses: 'fas fa-shopping-bag',
        path: ['/main/product-orders']
      },
      /*{
        name: 'Post',
        iconClasses: 'fas fa-shopping-bag',
        path: ['/main/post-orders']
      }*/
    ]
  },
  {
    name: 'Payment',
    iconClasses: 'fas fa-rupee-sign',
    path: ['/main/payments']
  },
  {
    name: 'Enquiries',
    iconClasses: 'fas fa-envelope',
    path: ['/main/enquiry']
  }
];
