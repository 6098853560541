import {Component, OnInit, Renderer2} from "@angular/core";
import {ToastrService} from "ngx-toastr";
import {AppService} from "@services/app.service";
import {Router} from "@angular/router";
import {NgxSpinnerService} from "ngx-spinner";
import {Subject, takeUntil} from 'rxjs';
import {ImageDetails} from "@/model/asset-details/ImageDetails";
import {PostDetails} from "@/model/post/PostDetails";
import {DatePipe} from '@angular/common';
import {ConfigService} from "@/config.service";
import {FeatureDetails} from "@/model/feature/FeatureDetails";

@Component({
    selector: 'app-post-detail',
    templateUrl: './post-detail.component.html',
    styleUrls: ['./post-detail.component.scss']
})

export class PostDetailsComponent implements OnInit {
    destroy$: Subject<boolean> = new Subject<boolean>();
    postID: number;
    postDetailById: PostDetails | undefined;
    postId: number;
    postName: string;
    phoneNo: string;
    emailId: string;
    friendlyDescription: string;
    postFriendlyName: string;
    price: number;
    tax: number;
    discount: number;
    description: string;
    isTrader: string;
    isApproved: string;
    postImageList: ImageDetails[] = [];
    postFeatureList: FeatureDetails[] = [];
    isModifyClicked: boolean = false;
    endPoint: string;
    availabilityDate: string;

    constructor(
        private renderer: Renderer2,
        private toastr: ToastrService,
        private appService: AppService,
        private router: Router,
        private spinner: NgxSpinnerService,
        private configService: ConfigService,
        private datePipe: DatePipe,
    ) {
    }

    ngOnInit() {
        this.getPostDetail();
        this.endPoint = this.configService.getConfig().END_POINT;
        window.scrollTo(0, 0);
    }

    ngOnDestroy() {
        localStorage.removeItem('postId');
    }

    getImageUrl(imageId: string): string {
        return `${this.endPoint}/api/image-details/get/${imageId}`;
    }

    getPostDetail() {
        this.spinner.show();
        this.postID = Number(localStorage.getItem('postId'));
        this.appService
            .getPostDetailsById(this.postID)
            .pipe(takeUntil(this.destroy$))
            .subscribe((data) => {
                    this.spinner.hide();
                    this.postDetailById = data;
                    console.log("post detail::", this.postDetailById);
                    this.endPoint = this.configService.getConfig().END_POINT;
                    const post = this.postDetailById;
                    this.postId = post.postId;
                    this.postName = post.postName;
                    this.postFriendlyName = post.friendlyName;
                    this.friendlyDescription = post.friendlyDescription;
                    this.isTrader = post.postedUserType;
                    this.phoneNo = post.phoneNumber;
                    this.postImageList = post.postImageList;
                    this.isApproved = post.approvalStatus;
                    this.price = post.price;
                    this.tax = post.tax;
                    this.discount = post.discount;
                    this.description = post.description;
                    this.postFeatureList = post.postFeatureList;
                    const date = new Date(post.availabilityDate);
                    this.availabilityDate = this.datePipe.transform(date, 'dd-MM-yyyy');
                },
                (error: any) => {
                    console.error("Error:", error);
                    this.spinner.hide();

                    const errorMessage = error?.error?.message || "Something went wrong, please try again";
                    this.toastr.error(errorMessage);
                })
    }

    approveStatus(approvalStatus: string, postId: number) {
        const postStatus = {
            postId: postId,
            approvalStatus: approvalStatus
        }
        this.spinner.show();
        this.appService
            .updatePostStatus(postStatus)
            .pipe(takeUntil(this.destroy$))
            .subscribe((data) => {
                    this.spinner.hide();
                    console.log("update post status::", data);
                    this.postDetailById = data;
                    this.toastr.success('Post approval status updated');
                    this.postDetailById.approvalStatus = data.approvalStatus;
                    this.getPostDetail();
                },
                (error: any) => {
                    console.error("Error:", error);
                    this.spinner.hide();

                    const errorMessage = error?.error?.message || "Something went wrong, please try again";
                    this.toastr.error(errorMessage);
                });
    }
}