import {Component, OnInit, Renderer2} from "@angular/core";
import {ToastrService} from "ngx-toastr";
import {AppService} from "@services/app.service";
import {Router} from "@angular/router";
import {NgxSpinnerService} from "ngx-spinner";
import {ConfigService} from "@/config.service";
import {Subject, takeUntil} from 'rxjs';
import {ProductOrders} from "@/model/orders/ProductOrders";
import {PostOrders} from "@/model/orders/PostOrders";
import {val} from "dom7";

@Component({
    selector: 'app-orders',
    templateUrl: './orders.component.html',
    styleUrls: ['./orders.component.scss']
})

export class OrdersComponent implements OnInit {
    destroy$: Subject<boolean> = new Subject<boolean>();
    productOrders: ProductOrders[] = [];
    tableProductOrders: ProductOrders[] = [];
    postOrders: PostOrders[] = [];
    tablePostOrders: PostOrders[] = [];
    productOrderStatus: string[] = [];
    orderStatus: string[] = [];
    postStatus: string[] = [];
    postOrderStatus: string[] = [];
    area: string;
    city: string;
    state: string;
    pinCode: string;
    searchText;
    orderType: string;

    constructor(
        private renderer: Renderer2,
        private toastr: ToastrService,
        private appService: AppService,
        private router: Router,
        private spinner: NgxSpinnerService,
        private configService: ConfigService
    ) {
    }

    ngOnInit() {
        /* this.getProductOrderList();
         this.getAllPostOrderList();*/
    }

   /* getProductOrderList() {
        this.appService.getAllProductOrders()
            .pipe(takeUntil(this.destroy$))
            .subscribe(
                (data) => {
                    this.productOrders = data;
                    console.log('product order list::', this.productOrders);
                    this.tableProductOrders = this.productOrders;

                    this.productOrders.forEach(order => {
                        const address = order.deliveryAddress;
                        this.area = address.area;
                        this.city = address.city;
                        this.state = address.state;
                        this.pinCode = address.pinCode;
                    });


                    const uniqueStatusSet = new Set<string>(this.productOrders.map(order => order.productOrderStatus));
                    const uniqueStatusArray = Array.from(uniqueStatusSet);
                    console.log(uniqueStatusArray);

                    if (this.orderType == 'Product') {
                        this.productOrderStatus = uniqueStatusArray;
                    }

                    this.orderStatus = this.productOrderStatus;
                    console.log('Product OD Status::', this.orderStatus);
                },
                (error: any) => {
                    console.log("error:", error.error?.msg || "Something went wrong, please try again");
                    this.spinner.hide();
                    if (error && error.error && error.error.msg) {
                        this.toastr.error(error.error.msg);
                    } else {
                        this.toastr.error("Something went wrong, please try again");
                    }
                },
                () => {
                    this.spinner.hide(); // Hide spinner on completion
                }
            );
    }

    getAllPostOrderList() {
        this.appService.getAllPostOrders()
            .pipe(takeUntil(this.destroy$))
            .subscribe(
                (data) => {
                    this.postOrders = data;
                    console.log('post order list::', this.postOrders);
                    this.tablePostOrders = this.postOrders;

                    this.postOrders.forEach(order => {
                        const address = order.deliveryAddress;
                        this.area = address.area;
                        this.city = address.city;
                        this.state = address.state;
                        this.pinCode = address.pinCode;
                    })

                    const uniquePostStatus = new Set<string>(this.postOrders.map(order => order.postOrderStatus));
                    const uniquePostStatusArray = Array.from(uniquePostStatus);
                    console.log(uniquePostStatusArray);

                    if (this.orderType == 'Post') {
                        this.postOrderStatus = uniquePostStatusArray;
                    }

                    this.orderStatus = this.postOrderStatus;
                    console.log('Post OD Status::', this.orderStatus);
                }, (error: any) => {
                    console.log("error:", error.error?.msg || "Something went wrong, please try again");
                    this.spinner.hide();
                    if (error && error.error && error.error.msg) {
                        this.toastr.error(error.error.msg);
                    } else {
                        this.toastr.error("Something went wrong, please try again");
                    }
                },
                () => {
                    this.spinner.hide();
                }
            )
    }

    selectOrderType(event: any) {
        const value = event.target.value;
        console.log('orderType::', value);
        this.orderType = value;
        if (this.orderType == 'Post') {
            this.getAllPostOrderList();
        } else {
            this.getProductOrderList();
        }
    }

    selectOrderStatus(event: any) {
        if (this.orderType == 'Product') {
            this.tableProductOrders = [];
            const value = event.target.value;
            console.log(value)
            for (let table of this.productOrders) {
                if (value == table.productOrderStatus) {
                    this.tableProductOrders.push(table);
                    console.log(table.productOrderStatus)
                }
            }
            console.log('OrderStatusBasedData::', this.tableProductOrders);
        } else {
            this.tablePostOrders = [];
            const value = event.target.value;
            for (let post of this.postOrders) {
                if (value == post.postOrderStatus) {
                    this.tablePostOrders.push(post);
                }
                console.log('Post Order Table List::', this.tablePostOrders);
            }
        }
    }*/
}