<div class="card card-outline card-primary">
  <div class="card-header text-center">
    <a [routerLink]="['/']" class="h1"><b>Admin</b>LTE</a>
  </div>
  <div class="card-body">
    <p class="login-box-msg">
      You are only one step a way from your new password, recover your
      password now.
    </p>
    <form (ngSubmit)="recoverPassword()" [formGroup]="recoverPasswordForm">
      <div class="input-group mb-3">
        <input
          autocomplete="off"
          class="form-control"
          formControlName="password"
          placeholder="Password"
          type="password"
        />
        <div class="input-group-append">
          <div class="input-group-text">
            <span class="fas fa-lock"></span>
          </div>
        </div>
      </div>
      <div class="input-group mb-3">
        <input
          autocomplete="off"
          class="form-control"
          formControlName="confirmPassword"
          placeholder="Confirm Password"
          type="password"
        />
        <div class="input-group-append">
          <div class="input-group-text">
            <span class="fas fa-lock"></span>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <pf-button [block]="true" [type]="'submit'">
            Change password
          </pf-button>
        </div>
      </div>
    </form>

    <p class="mt-3 mb-1">
      <a [routerLink]="['/login']">LoginDetails</a>
    </p>
  </div>
</div>
