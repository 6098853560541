import {Component, OnInit, Renderer2} from "@angular/core";
import {ToastrService} from "ngx-toastr";
import {AppService} from "@services/app.service";
import {Router} from "@angular/router";
import {NgxSpinnerService} from "ngx-spinner";
import {ConfigService} from "@/config.service";
import {Subject, takeUntil, tap} from 'rxjs';
import {PostOrders} from "@/model/orders/PostOrders";
import {OrderList} from "@/model/orders/OrderList";
import {DateTime} from 'luxon';

@Component({
    selector: 'app-orders',
    templateUrl: './post-orders.component.html',
    styleUrls: ['./post-orders.component.scss']
})

export class PostOrdersComponent implements OnInit {
    destroy$: Subject<boolean> = new Subject<boolean>();
    postOrders: PostOrders[] = [];
    tablePostOrders: PostOrders[] = [];
    orderStatus: string[] = [];
    postStatus: string[] = [];
    postOrderStatus: string[] = [];
    area: string;
    city: string;
    state: string;
    pinCode: string;
    searchText;
    orderType: string;
    postOrderDetailModal = false;
    postOrderDetails: PostOrders | undefined;
    orderID: number;
    orderDate: string;
    orderList: OrderList[] = [];
    netAmount: number;
    totalAmount: number;
    payableAmount: number;
    paymentStatus: string;
    addressType: string;
    doorNo: string;
    streetName: string;
    deliveryArea: string;
    village: string;
    deliveryCity: string;
    deliveryState: string;
    deliveryPinCode: string;
    landmark: string;

    constructor(
        private renderer: Renderer2,
        private toastr: ToastrService,
        private appService: AppService,
        private router: Router,
        private spinner: NgxSpinnerService,
        private configService: ConfigService
    ) {
    }

    ngOnInit() {
        this.getAllPostOrderList();
    }

    /*getAllPostOrderList() {
        this.appService.getAllPostOrders()
            .pipe(takeUntil(this.destroy$))
            .subscribe(
                (data) => {
                    this.postOrders = data;
                    console.log('post order list::', this.postOrders);
                    this.tablePostOrders = this.postOrders;

                    this.postOrders.forEach(order => {
                        const address = order.deliveryAddress;
                        this.area = address.area;
                        this.city = address.city;
                        this.state = address.state;
                        this.pinCode = address.pinCode;
                    })

                    const uniquePostStatus = new Set<string>(this.postOrders.map(order => order.postOrderStatus));
                    const uniquePostStatusArray = Array.from(uniquePostStatus);
                    console.log(uniquePostStatusArray);
                        this.postOrderStatus = uniquePostStatusArray;
                        console.log('Post OD Status::', this.postOrderStatus);
                }, (error: any) => {
                    console.log("error:", error.error?.msg || "Something went wrong, please try again");
                    this.spinner.hide();
                    if (error && error.error && error.error.msg) {
                        this.toastr.error(error.error.msg);
                    } else {
                        this.toastr.error("Something went wrong, please try again");
                    }
                },
                () => {
                    this.spinner.hide();
                }
            )
    }*/


    getAllPostOrderList() {
        this.appService.getAllPostOrders()
            .pipe(
                takeUntil(this.destroy$),
                tap(data => {
                    const address = data.map(postOrders => postOrders.deliveryAddress);
                    this.postOrderStatus = Array.from(new Set(data.map(order => order.postOrderStatus)));
                }),
            )
            .subscribe(
                (postOrders) => {
                    this.postOrders = postOrders;
                    /* this.tablePostOrders = postOrders;*/
                    console.log('post order list::', this.postOrders);
                },
                (error: any) => {
                    console.error("Error:", error);
                    this.spinner.hide();

                    const errorMessage = error?.error?.message || "Something went wrong, please try again";
                    this.toastr.error(errorMessage);
                },
                () => {
                    this.spinner.hide();
                }
            );
    }

    /*selectOrderStatus(event: any) {
        this.tablePostOrders = [];
        const value = event.target.value;
        for (let post of this.postOrders) {
            if (value == post.postOrderStatus) {
                this.tablePostOrders.push(post);
            }
            console.log('Post Order Table List::', this.tablePostOrders);
        }
    }*/
    selectOrderStatus(event: any) {
        const value = event.target.value;
        this.tablePostOrders = this.postOrders.filter(post => post.postOrderStatus === value);
        console.log('Post Order Status Data::', this.tablePostOrders);
    }

    openPostOrderModal(postOrderId: number) {
        this.postOrderDetailModal = true;
        this.appService.getPostOrderByID(postOrderId)
            .pipe(takeUntil(this.destroy$),
                tap((postOrder) => {
                    this.orderList = postOrder.listOrderDetails;
                    const address = postOrder.deliveryAddress;
                    this.addressType = address.addressType;
                    this.deliveryArea = address.area;
                    this.deliveryCity = address.city;
                    this.deliveryPinCode = address.pinCode;
                    this.deliveryState = address.state;
                    this.landmark = address.landmark;
                    this.doorNo = address.doorNo;
                    this.streetName = address.streetName;
                })
            )
            .subscribe((postOrderDetails) => {
                    console.log('Post Order Detail::', postOrderDetails);
                    this.postOrderDetails = postOrderDetails;
                    this.orderID = this.postOrderDetails.postOrderId;
                    const epochDate = this.postOrderDetails.postOrderDate;
                    const dt = DateTime.fromMillis(epochDate);
                    this.orderDate = dt.toFormat('cccc, MMMM dd, yyyy h:mm a');
                    this.totalAmount = this.postOrderDetails.totalAmount;
                    this.payableAmount = this.postOrderDetails.payableAmount;
                    this.netAmount = this.postOrderDetails.netAmount;
                    this.paymentStatus = this.postOrderDetails.paymentStatus;
                },
                (error: any) => {
                    console.error("Error:", error);
                    this.spinner.hide();

                    const errorMessage = error?.error?.message || "Something went wrong, please try again";
                    this.toastr.error(errorMessage);
                },
                () => {
                    this.spinner.hide();
                }
            )
    }
}