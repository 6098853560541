import {Component, OnInit, Renderer2} from '@angular/core';
import {ToastrService} from "ngx-toastr";
import {AppService} from "@services/app.service";
import {Router} from "@angular/router";
import {NgxSpinnerService} from "ngx-spinner";
import {Subject, takeUntil} from 'rxjs';
import {PostDetails} from "@/model/post/PostDetails";
import {ImageDetails} from "@/model/asset-details/ImageDetails";
import {ConfigService} from "@/config.service";
import {tap} from "rxjs/operators";

@Component({
    selector: 'app-post',
    templateUrl: './post.component.html',
    styleUrls: ['./post.component.scss']
})
export class PostComponent implements OnInit {
    destroy$: Subject<boolean> = new Subject<boolean>();
    searchText;
    postDetailsList: PostDetails[] = [];
    postImageList: ImageDetails[] = [];
    endPoint: string;
    imageId: number;
    postNameFilter: string | null = null;
    postName: String[] = [];
    postLocationFilter: string | null = null;
    postLocation: String[] = [];
    postDetails: PostDetails[] = [];
    postListData: PostDetails[] = [];

    constructor(
        private renderer: Renderer2,
        private toastr: ToastrService,
        private appService: AppService,
        private router: Router,
        private spinner: NgxSpinnerService,
        private configService: ConfigService
    ) {
    }

    ngOnInit() {
        this.getAllPosts();
        this.endPoint = this.configService.getConfig().END_POINT;
    }

    viewPostDetail(postId: number) {
        localStorage.setItem("postId", String(postId));
        console.log("id::", postId)
        this.router.navigate(['./main/post-detail-view']);
    }

    getImageUrl(imageId: string): string {
        return `${this.endPoint}/api/image-details/get/${imageId}`;
    }

    getAllPosts() {
        this.spinner.show();
        this.appService
            .getAllPostDetails()
            .pipe(takeUntil(this.destroy$),
                tap((posts) => {
                    this.postName = Array.from(new Set(posts.map(name => name.postName)));
                })
            )
            .subscribe((data) => {
                    this.spinner.hide();
                    this.postDetailsList = data;
                    console.log("post list::", this.postDetailsList);
                    this.postDetails = this.postDetailsList.sort((a, b) => b.postId - a.postId);
                    this.endPoint = this.configService.getConfig().END_POINT;
                },
                (error: any) => {
                    console.error("Error:", error);
                    this.spinner.hide();

                    const errorMessage = error?.error?.message || "Something went wrong, please try again";
                    this.toastr.error(errorMessage);
                });
    }

    selectPostName(event: any) {
        const value = event.target.value;
        this.postDetails = this.postDetailsList.filter(name => name.postName === value);
        if (value == 'All') {
            this.postDetails = this.postDetailsList;
        }
    }
}
