import {Injectable, OnInit} from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import {Observable} from 'rxjs';
import {AppService} from '@services/app.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild, OnInit{
  userType: string;
  constructor(private router: Router, private appService: AppService) {
  }

  ngOnInit() {
    this.userType = localStorage.getItem('userType');
    console.log(this.userType);
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.getProfile();
  }

  canLoad(route: string):
      | boolean
      | UrlTree
      | Observable<boolean | UrlTree>
      | Promise<boolean | UrlTree> {
    this.userType = localStorage.getItem('userType');
    if (this.userType == 'Admin') {
      return true;
    } else {
      this.router.navigate([''])
    }
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    this.canLoad(state.url);
    return this.canActivate(next, state);
  }

  async getProfile() {
    if (this.appService.user) {
      return true;
    }

    try {
      await this.appService.getProfile();
      return true;
    } catch (error) {
      return false;
    }
  }
}
