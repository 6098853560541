export interface LoginDetails {
    authHeader: string;
    statusCode: number;
    login: LoginUserDetails;
}

export interface LoginUserDetails {
    loginId: number;
    userName: string;
    userType: string;
    phoneNumber: string;
    role: string;
    password: string;
    registrationId: number;
    verificationStatus: string;
    enabled: true;
    createdAt: number;
    updatedAt: number;
    countryCode: string;
}