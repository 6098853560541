<section class="content">
    <div class="container-fluid">
        <div class="row mt-2 mb-5">
            <div class="col-lg-12">
                <div class="row">
                    <div class="col-lg-3 mt-lg-3 mt-5">
                        <h3 class="mt-3 mb-3">Product Details</h3>
                    </div>
                    <div class="col-lg-9">
                        <div class="row">
                            <div class="col-lg-9">
                                <div class="row" *ngIf="isVerified != 'Approved'">
                                    <div class="col-md-3 col-lg-3 mt-3">
                                        <button class="btn-success-green btn-block"
                                                (click)="approveStatus('Approved', productId)">
                                            Approve
                                        </button>
                                    </div>
                                    <div class="col-md-3 col-lg-3 mt-3">
                                        <button class="btn-danger-red btn-block"
                                                (click)="approveStatus('Not Approved', productId)">
                                            Reject
                                        </button>
                                    </div>
                                    <div class="col-md-3 col-lg-3 mt-3">
                                        <button class="btn-secondary-light btn-block"
                                                (click)="approveStatus('Hold', productId)">Hold
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3 col-lg-3 mt-3" *ngIf="isVerified == 'Approved'">
                                <div class="row">
                                    <div class="col-lg-9 text-end">
                                        <span class="badge-success-green btn-block p-3">Approved</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-lg-8 mt-2">
                        <div class="row mt-2">
                            <div class="col-12 col-lg-4 col-md-6 mt-2">
                                <div class="bg-white-detail p-3">
                                    <p class="mb-0">Product Name</p>
                                    <span class="mt-0 fw-bold">{{productName}}</span>
                                </div>
                            </div>
                            <div class="col-12 col-lg-4 col-md-6 mt-2">
                                <div class="bg-white-detail p-3">
                                    <p class="mb-0">Uniqueness</p>
                                    <span class="mt-0 fw-bold">{{unique}}</span>
                                </div>
                            </div>
                            <div class="col-12 col-lg-4 col-md-6 mt-2">
                                <div class="bg-white-detail p-3">
                                    <p class="mb-0">Phone Number</p>
                                    <span class="mt-0 fw-bold">{{phoneNo}}</span>
                                </div>
                            </div>
                            <div class="col-12 col-lg-4 col-md-6 mt-2">
                                <div class="bg-white-detail p-3">
                                    <p class="mb-0">Description</p>
                                    <span class="mt-0 fw-bold">{{description}}</span>
                                </div>
                            </div>
                            <div class="col-12 col-lg-4 col-md-6 mt-2">
                                <div class="bg-white-detail p-3">
                                    <p class="mb-0">Location</p>
                                    <span class="mt-0 fw-bold">{{location}}</span>
                                </div>
                            </div>
                            <!--<div class="col-12 col-lg-4 col-md-6 mt-2">
                                <div class="bg-white-detail p-3">
                                    <p class="mb-0">Discount</p>
                                    <span class="mt-0 fw-bold">{{discount}} %</span>
                                </div>
                            </div>-->
                            <div class="col-12 col-lg-4 col-md-6 mt-2">
                                <div class="bg-white-detail p-3">
                                    <p class="mb-0">Price</p>
                                    <span class="mt-0 fw-bold">RS.{{price | currency:'INR':''}}</span>
                                </div>
                            </div>

                            <div class="col-12 col-lg-4 col-md-6 mt-2">
                                <div class="bg-white-detail p-3">
                                    <p class="mb-0">Tax</p>
                                    <span class="mt-0 fw-bold">{{tax}} %</span>
                                </div>
                            </div>

                            <div class="col-12 col-lg-4 col-md-6 mt-2">
                                <div class="bg-white-detail p-3">
                                    <p class="mb-0">Net Price</p>
                                    <span class="mt-0 fw-bold">RS.{{netPrice | currency:'INR':''}}</span>
                                </div>
                            </div>

                            <div class="col-12 col-lg-4 col-md-6 mt-2">
                                <div class="bg-white-detail p-3">
                                    <p class="mb-0">Quantity Value</p>
                                    <span class="mt-0 fw-bold">{{quantityValue}} {{quantityType}}</span>
                                </div>
                            </div>

                           <!-- <div class="col-12 col-lg-4 col-md-6 mt-2">
                                <div class="bg-white-detail p-3">
                                    <p class="mb-0">Value</p>
                                    <span class="mt-0 fw-bold">{{quantityValue}}</span>
                                </div>
                            </div>-->
                        </div>
                    </div>
                    <div class="col-12 col-lg-4 col-md-12 p-3">
                        <div class="row mt-4 mb-3">
                            <p class="mb-0">Product Images</p>
                        </div>
                        <div class="row">
                            <div class="col-lg-3 col-6 col-md-4 mt-2" *ngFor="let image of this.productImageList">
                                <img *ngIf="image?.imageId" src="{{endPoint}}/api/image-details/get/{{image.imageId}}"
                                     class="img-fluid" loading="lazy" (click)="openModal(image)">
                            </div>
                        </div>
                    </div>
                    <div class="container-fluid col-lg-12 col-12">
                        <div class="row mt-4">
                            <div class="col-lg-12 col-12">
                                <table class="table bg-white-detail table-responsive-sm table-borderless">
                                    <thead>
                                    <th>Feature Name</th>
                                    <th>Feature Value</th>
                                    <th>Friendly Name</th>
                                    <th>Friendly Value</th>
                                    </thead>
                                    <tbody>
                                    <tr *ngFor="let feature of this.featureList">
                                        <td>{{feature.featureName}}</td>
                                        <td>{{feature.featureValue}}</td>
                                        <td>{{feature.friendlyName}}</td>
                                        <td>{{feature.friendlyValue}}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="modal" id="imageModal" tabindex="-1" role="dialog" aria-labelledby="imageModalLabel" aria-hidden="true" [style.display]="imageModalShow ? 'block' : 'none'" style="background-color: rgba(87,86,86,0.64)">
    <div class="modal-dialog modal-lg modal-dialog-scrollable" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="imageModalLabel">Image Preview</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="imageModalShow = false">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-12 text-center">
                        <img [src]="selectedImageUrl" class="img-fluid">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" type="jelly-box">
    <div class="loader">
        <div class="inner one"></div>
        <div class="inner two"></div>
        <div class="inner three"></div>
    </div>
</ngx-spinner>
